import { IPersonalInfoCmsMobile, IPortabilityMobileCMS } from '@vfit/consumer/data-access';
import { ISimTypeCardData } from './simTypeSelectionCardWinback.models';

export const organizeSimTypeCard = (
  dataFromCms: IPersonalInfoCmsMobile,
  errorPortability: IPortabilityMobileCMS
): ISimTypeCardData => ({
  cardTitle: dataFromCms?.personalinfomobile?.simType?.card?.title || '',
  cardDescription: dataFromCms?.personalinfomobile?.simType?.card?.description || '',
  modalInfo: {
    textLink: dataFromCms?.personalinfomobile?.simType?.modalInfo?.textLink || '',
    titleModal: dataFromCms?.personalinfomobile?.simType?.modalInfo?.title || '',
    descriptionModal: dataFromCms?.personalinfomobile?.simType?.modalInfo?.description || '',
  },
  migrationCodeError: {
    title: errorPortability?.portabilitymobile?.errorOnPortability?.title || '',
    description:
      errorPortability?.portabilitymobile?.errorOnPortability?.message || '',
    buttonLabel: errorPortability?.portabilitymobile?.errorOnPortability?.buttonLabel || '',
  },
  genericsError: {
    title: errorPortability?.portabilitymobile?.errorModal?.title || '',
    description:
      errorPortability?.portabilitymobile?.errorModal?.message || '',
    buttonLabel: errorPortability?.portabilitymobile?.errorModal?.buttonLabel || '',
  },
  numberError: {
    title: errorPortability?.portabilitymobile?.errorNumber?.title || '',
    description:
      errorPortability?.portabilitymobile?.errorNumber?.message || '',
    buttonLabel: errorPortability?.portabilitymobile?.errorNumber?.buttonLabel || '',
  },
});
