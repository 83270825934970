import { checkWindow, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import {
  checkIsApp,
  CMS_ID,
  DXL_NBO_CAMPAIGNS,
  DXL_NBO_CAMPAIGN,
  FLOW_TYPE,
  getAddressInfoByPlaceId,
  getDXLProductPrice,
  getRedirectCoverage,
  IDXLNboProduct,
  IProduct,
  OFFER_ID,
  PLACE_ID,
  retrieveAppSession,
  retrieveProduct,
  updateProduct,
} from '@vfit/consumer/data-access';
import {
  FLOW_TYPE_DYNAMIC_SHOPPING_CART,
  IOfferingServiceabilityResponse,
} from '@vfit/shared/models';
import { errorManager, ErrorService, LoggerInstance } from '@vfit/shared/data-access';
import { IUseDynamicShoppingCartResponse } from './useDynamicShoppingCartFlow.models';

export const DEFAULT_DYNAMIC_SHOPPING_CART_RESPONSE: IUseDynamicShoppingCartResponse = {
  isLoading: true,
  isSuccess: false,
  isError: false,
  flowType: '',
  errorCode: -1,
  product: undefined,
};

export const DEFAULT_DYNAMIC_SHOPPING_CART_RESPONSE_SUCCESS: IUseDynamicShoppingCartResponse = {
  isLoading: false,
  isSuccess: true,
  isError: false,
  errorCode: -1,
  flowType: '',
  product: undefined,
};

// region IADD Logic

export const iAddProductNotFound = () => {
  errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
    title: 'Ops!',
    message: 'Si è verificato un errore durante il recupero del prodotto. Riprova più tardi',
    actionEvent: () => {
      localStorage.removeItem('shoppingCart');
      window.location.reload();
    },
    actionText: 'Riprova',
    disableTrack: true,
  });
};

const retrieveIAddOffer = (
  product: IProduct,
  allProducts: IProduct[],
  dxlNboCampaign: IDXLNboProduct,
  serviceabilityIds: string[]
): IProduct | null => {
  const alternativeProductOfferList = product?.alternativeProductOfferList || [];
  if (!serviceabilityIds || (serviceabilityIds && serviceabilityIds.length === 0)) return null;

  // filter alternative offers inside the product
  const filteredAlternativeProducts: IProduct[] =
    allProducts?.filter((prod) => alternativeProductOfferList?.includes(prod?.cmsId)) || [];

  // search Dxl product from filteredAlternative
  if (serviceabilityIds?.includes(dxlNboCampaign?.bundlePO)) {
    const dxlProduct =
      filteredAlternativeProducts?.find(
        (prod) => prod.offerId?.toString() === dxlNboCampaign?.bundlePO
      ) || null;
    if (dxlProduct && dxlNboCampaign?.bundlePO) {
      localStorage.setItem(DXL_NBO_CAMPAIGN, JSON.stringify(dxlNboCampaign));
      // Update DXL Price
      const price = getDXLProductPrice(dxlNboCampaign);
      return {
        ...dxlProduct,
        offerId: +dxlNboCampaign.bundlePO,
        price,
      };
    }
  }

  return (
    filteredAlternativeProducts?.find(
      (prod) => serviceabilityIds?.includes(prod?.offerId?.toString()) && !prod?.isLockInProduct
    ) || null
  );
};

export const getIAddConfiguration = (allProducts: IProduct[]): IProduct | undefined => {
  // Mock product IAdd => 51...
  const product = retrieveProduct();
  if (!product) return undefined;

  const dxlCampaignsFixed: IDXLNboProduct[] = getFromLocalStorageByKey(DXL_NBO_CAMPAIGNS);
  const offeringServiceability: IOfferingServiceabilityResponse =
    getFromLocalStorageByKey('offeringServiceability');
  if (!offeringServiceability || allProducts?.length === 0) return null;
  // All Serviceability bundle ids
  const serviceabilityIds: string[] = offeringServiceability?.offeringServiceabilityItem?.map(
    (item) => item?.offering?.id?.toString()
  );
  // Search Dxl ID bundle
  const foundedDxlNbo: IDXLNboProduct =
    dxlCampaignsFixed?.find((dxlCampaignsFixedProduct) =>
      serviceabilityIds?.includes(dxlCampaignsFixedProduct.bundlePO)
    ) || dxlCampaignsFixed?.[0];

  const backupOffer = retrieveIAddOffer(product, allProducts, foundedDxlNbo, serviceabilityIds);
  LoggerInstance.debug('IADD PRODUCT', backupOffer);
  if (!backupOffer) return undefined;

  updateProduct({ ...backupOffer });
  return backupOffer;
};

// endregion

export const retrieveCartInfo = async (allProducts: IProduct[]) => {
  if (!checkWindow()) return;
  const queryString = new URLSearchParams(window?.location?.search);
  const placeId = queryString.get(PLACE_ID);
  const offerId = queryString.get(OFFER_ID);
  const cmsId = queryString.get(CMS_ID);
  const flowType = queryString.get(FLOW_TYPE);
  placeId && localStorage.setItem('placeId', placeId);

  if (checkIsApp()) {
    retrieveAppSession();
  }

  if (!flowType) {
    // eslint-disable-next-line consistent-return
    return false;
  }
  if (flowType == FLOW_TYPE_DYNAMIC_SHOPPING_CART.SHOPPING_CART_FIXED) {
    if (!placeId && (!cmsId || !offerId)) {
      // eslint-disable-next-line consistent-return
      return false;
    }
  }

  if (flowType == FLOW_TYPE_DYNAMIC_SHOPPING_CART.SHOPPING_CART_MOBILE) {
    if (!cmsId && !offerId) {
      // eslint-disable-next-line consistent-return
      return false;
    }
  }

  let address;
  let product: IProduct;
  if (placeId) {
    address = await getAddressInfoByPlaceId(placeId);
  }
  if (cmsId || offerId) {
    product = allProducts.find((productLocal) => {
      if (cmsId) return productLocal?.cmsId?.toString() == cmsId;
      return productLocal?.offerId?.toString() == offerId;
    });
    if (product) updateProduct(product);
  }

  // eslint-disable-next-line consistent-return
  return {
    ...address,
    product,
    redirectCoverage: getRedirectCoverage(),
    onConfirmAddress: !!product && !!address,
    alternativeProductList: product?.alternativeProductOfferList || [],
    flowType,
  };
};
