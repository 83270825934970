import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  checkBypassApiCallFromSIA,
  CONSUMER_CMS_SHOPPING_CART,
  IFindCallerResponse,
  IGlobalConfigCMS,
  useAuthorizeMutation,
  useCmsConfig,
  useGetPaymean,
  usePayment,
  useReserveEquipmentMutation,
} from '@vfit/consumer/data-access';
import { LoggerInstance } from '@vfit/shared/data-access';
import { ISIAFlowType } from '@vfit/shared/models';
import { API, getFromLocalStorageByKey, PAGES } from '@vfit/shared/data-access';

interface IAPIError {
  error: string;
  errorMessage: string;
}

export const usePaymentAuthorizeFlow = (
  selectedItem: string | undefined,
  siaFlowType?: ISIAFlowType,
  authorizationOperationMethod?: 'capture' | 'verify'
): {
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  payMeanIsSuccess: boolean;
  payMeanIsError: boolean;
  paymentIsError: boolean;
} => {
  const queryClient = useQueryClient();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const newPayMeanEnabler = !!selectedItem;

  const globalConfig = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GLOBAL_CONFIG
  ) as IGlobalConfigCMS;

  // const findCallerData: any | IAPIError | undefined = queryClient.getQueryData('findCaller');
  // const hasPayMean: boolean = !!findCallerData?.paymean
  const reserveEquipmentData: any | IAPIError | undefined =
    queryClient.getQueryData('reserveEquipment');
  const authorizeData: any | IAPIError | undefined = queryClient.getQueryData('authorize');

  const {
    data: paymentData,
    isSuccess: paymentIsSuccess,
    isLoading: paymentIsLoading,
    isError: paymentIsError,
  } = usePayment({
    enabled: !checkBypassApiCallFromSIA(),
  });
  const {
    data: payMeanData,
    isSuccess: payMeanIsSuccess,
    isError: payMeanIsError,
  } = useGetPaymean(
    {
      enabled: paymentIsSuccess && !checkBypassApiCallFromSIA(),
    },
    globalConfig
  );

  const reserveEquipmentMutation = useReserveEquipmentMutation();
  const authorizeMutation = useAuthorizeMutation();

  useEffect(() => {
    if (paymentIsLoading) {
      setIsLoading(true);
    }
  }, [paymentIsLoading]);

  useEffect(() => {
    // IMPORTANT: FIX TO UPDATE THE LOGIC FOR INACTIVE NEXT RECOGNIZED
    const findCaller: IFindCallerResponse = getFromLocalStorageByKey('findCaller');
    LoggerInstance.debug('AUTHORIZE payMeanData :', payMeanData);
    LoggerInstance.debug('AUTHORIZE payMeanData.payMeans :', payMeanData?.payMeans);
    if (
      payMeanData?.payMeans &&
      findCaller?.customerHasActiveSubs &&
      payMeanIsSuccess &&
      !reserveEquipmentMutation.isSuccess &&
      !newPayMeanEnabler &&
      !authorizeData &&
      selectedItem
    ) {
      // || hasPayMean
      reserveEquipmentMutation.mutate();
    }
    if (
      payMeanData?.payMeans &&
      reserveEquipmentMutation.isSuccess &&
      !authorizeMutation.isSuccess &&
      selectedItem
    ) {
      // || hasPayMean
      authorizeMutation.mutate({
        payMeanType: selectedItem,
        ...(authorizationOperationMethod && {
          authorizationOperation: authorizationOperationMethod,
        }),
        ...(siaFlowType && { siaFlowType }),
      });
    }
  }, [
    payMeanIsSuccess,
    reserveEquipmentMutation.isSuccess,
    authorizeMutation.isSuccess,
    newPayMeanEnabler,
    Boolean(payMeanData?.payMeans),
  ]);

  useEffect(() => {
    if (newPayMeanEnabler) {
      authorizeMutation.mutate({
        payMeanType: selectedItem,
        ...(authorizationOperationMethod && {
          authorizationOperation: authorizationOperationMethod,
        }),
        ...(siaFlowType && { siaFlowType }),
      });
      setIsLoading(true);
    }
  }, [newPayMeanEnabler, selectedItem]);

  useEffect(() => {
    if (paymentIsError && paymentData) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage('ERROR - payment');
    }
    if (payMeanIsError && payMeanData) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage('ERROR - payMean');
    }
    if (reserveEquipmentMutation.isError && reserveEquipmentData) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage(reserveEquipmentData?.errorMessage);
    }
    if (authorizeMutation.isError && authorizeData) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage(authorizeData?.errorMessage);
    }
  }, [
    paymentIsError,
    payMeanIsError,
    reserveEquipmentMutation.isError,
    authorizeMutation.isError,
    paymentData,
    payMeanData,
    reserveEquipmentData,
    authorizeData,
  ]);

  useEffect(() => {
    if (authorizeMutation.isSuccess && !!authorizeData) {
      setIsLoading(false);
      setIsSuccess(true);
    }
  }, [authorizeMutation.isSuccess, authorizeData]);

  useEffect(() => {
    if (payMeanIsSuccess) {
      setIsLoading(false);
    }
  }, [payMeanIsSuccess]);

  return {
    isSuccess,
    isLoading,
    isError,
    errorMessage,
    payMeanIsSuccess,
    payMeanIsError,
    paymentIsError,
  };
};
