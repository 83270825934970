import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  CONSUMER_CMS_SHOPPING_CART,
  ErrorCodes,
  errorMock,
  IDigitalOperatorsConfig,
  IGenericErrorCMS,
  IPersonalInfoCmsMobile,
  IPortabilityMobileCMS,
  IShoppingCartResponse,
  useCmsConfig,
  usePage,
} from '@vfit/consumer/data-access';
import {
  API,
  getFromLocalStorageByKey,
  openPopup,
  ITracking,
  useTracking,
  volaModalManager,
  ITrackView,
} from '@vfit/shared/data-access';
import { IClickToCallMeData, ICommonData } from '@vfit/shared/models';
import { FallbackModal } from '@vfit/consumer/components';
import { SoftManagerService } from '@vfit/consumer/providers';
import { removeCurrency } from '@vfit/consumer/hooks';
import {
  checkOperator,
  checkProductAvaiable,
  findOperatorValue,
  organizeNewProductData,
  organizeNoOfferPopUp,
  organizeSimTypeCard,
} from './simTypeSelectionCard.utils';
import { useCheckout } from '../../../iBuyMobile.context';
import { ISimTypeCardData } from './simTypeSelection.models';
import { SelectionCard } from './components/SelectionCard/selectionCard';
import { ETrackConvergenceModal } from '../CampaignCard/components/ConvergenceModal/convergenceModal.models';
import { useDigitalBundle } from '../../hooks/UseDigitalBundle/useDigitalBundle';
import { LoadingCard } from './components/LoadingCard/loadingCard';
import { getTagging, getUserInfo } from '../../checkout.utils';
import { INoOfferModalConfig } from './components/NoOfferModal/noOfferModal.models';
import { NoOfferModal } from './components/NoOfferModal/noOfferModal';

const SimTypeSelectionCard = ({ handleOnChangeEnableGoNext }: ICommonData) => {
  const {
    portability,
    ICCID,
    isModalButtonSticky,
    product,
    checkoutErrors,
    portabilityStatus,
    setPortabilityStatus,
    setSlidesGoBack,
    setProduct,
  } = useCheckout();
  const [enablerPortability, setEnablerPortability] = useState(false);
  const [isFallbackModalOpen, setIsFallbackModalOpen] = useState(false);
  const [hasAccepted, setHasAccepted] = useState(false);
  const [avaiableProduct, setAvaiableProduct] = useState(product);
  const { page, products: allProducts } = usePage();
  const queryClient = useQueryClient();
  const shoppingCart = getFromLocalStorageByKey('shoppingCart');
  const genericError = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GENERIC_ERROR_MOBILE
  ) as IGenericErrorCMS;
  const dataFromCms = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_PERSONAL_DATA_MOBILE
  ) as IPersonalInfoCmsMobile;
  const errorPortability = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_PORTABILITY_MOBILE
  ) as IPortabilityMobileCMS;
  const cmsOperatorsConfig =
    (useCmsConfig(
      CONSUMER_CMS_SHOPPING_CART,
      API.CMS_GET_DIGITAL_BUNDLE_OPERATORS
    ) as IDigitalOperatorsConfig) || {};
  const volaClickToCallData = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_CLICK_TO_CALL_CONF
  ) as IClickToCallMeData;
  const organizedDataForSim = organizeSimTypeCard(
    dataFromCms,
    errorPortability
  ) as ISimTypeCardData;

  const initialNoOfferConfig: INoOfferModalConfig = {
    title: '',
    description: '',
    buttonLabel: '',
    isOpen: false,
  };
  const [noOfferConfig, setNoOfferConfig] = useState<INoOfferModalConfig>(initialNoOfferConfig);

  const { elements } = page?.params || {};

  const redirectHomePage = () => {
    window.location.href = '/';
  };

  const handleAccept = () => {
    localStorage.setItem('tProd_backUp', JSON.stringify(product));
    setProduct({
      ...avaiableProduct,
      slug: product?.slug,
      shoppingCartUrl: product?.shoppingCartUrl,
    });
    setIsFallbackModalOpen(false);
    setHasAccepted(true);
  };

  const handleClose = () => {
    setIsFallbackModalOpen(false);
    redirectHomePage();
  };

  const {
    isError: isAcceptError,
    isLoading: isAcceptLoading,
    isSuccess: isAcceptSuccess,
    error: acceptError,
  } = useDigitalBundle({
    enabler: hasAccepted,
    product: avaiableProduct,
  });

  // region Tagging

  const { trackWithNewDatalayer, trackView } = useTracking({
    event: ['view'],
    disableInitialTrack: true,
  });

  const buildTrackingObject = (step: ETrackConvergenceModal): ITracking => {
    let trackingObject = {
      event: [''],
      event_label: '',
      opts: {},
      cartProduct: {
        cart_id: (shoppingCart as IShoppingCartResponse)?.id,
        cart_product_id: `${avaiableProduct?.offerId}`,
        cart_product_name: avaiableProduct?.slug,
        cart_product_category: avaiableProduct?.productCategory,
        cart_product_price: avaiableProduct?.price && removeCurrency(avaiableProduct.price),
        cart_product_quantity: '1',
        cart_total: avaiableProduct?.price && removeCurrency(avaiableProduct.price),
      },
      pageProduct: {
        product_name: avaiableProduct?.slug || '',
        product_price: avaiableProduct?.price ? removeCurrency(avaiableProduct?.price) : '',
        product_id: `${avaiableProduct?.offerId}`,
        product_quantity: '1',
        product_category: 'mobile',
      },
      visitorTrackingOpts: getUserInfo(),
    };

    switch (step) {
      case ETrackConvergenceModal.OFFER_STEP: {
        const { opts } = getTagging('pop up');
        trackingObject = {
          ...trackingObject,
          event: ['checkout_step4.4.1'],
          event_label: 'offer exchange',
          opts: {
            ...opts,
            versatile_prop: findOperatorValue(portability.portabilityMigration?.currentProvider),
          },
        };
        break;
      }
      case ETrackConvergenceModal.DETAILS_STEP: {
        const { opts } = getTagging('offerDetails');
        trackingObject = {
          ...trackingObject,
          event: ['checkout_step4.4.2'],
          event_label: 'offer exchange details',
          opts: {
            ...opts,
            versatile_prop: findOperatorValue(portability.portabilityMigration?.currentProvider),
          },
        };
        break;
      }
    }

    return trackingObject;
  };

  useEffect(() => {
    if (isAcceptLoading) {
      const { opts } = getTagging('loader');
      trackWithNewDatalayer({
        event: ['checkout_step4.4.3'],
        event_label: 'offer exchange loader',
        opts: {
          ...opts,
          versatile_prop: findOperatorValue(portability.portabilityMigration?.currentProvider),
        },
        cartProduct: {
          cart_id: (shoppingCart as IShoppingCartResponse)?.id,
          cart_product_id: `${avaiableProduct?.offerId}`,
          cart_product_name: avaiableProduct?.slug,
          cart_product_category: avaiableProduct?.productCategory,
          cart_product_price: avaiableProduct?.price && removeCurrency(avaiableProduct.price),
          cart_product_quantity: '1',
          cart_total: avaiableProduct?.price && removeCurrency(avaiableProduct.price),
        },
        pageProduct: {
          product_name: avaiableProduct?.slug || '',
          product_price: avaiableProduct?.price ? removeCurrency(avaiableProduct?.price) : '',
          product_id: `${avaiableProduct?.offerId}`,
          product_quantity: '1',
          product_category: 'mobile',
        },
        visitorTrackingOpts: getUserInfo(),
      });
    }
  }, [isAcceptLoading]);

  useEffect(() => {
    if (
      !portability.portabilityWant ||
      (isAcceptSuccess && portabilityStatus.isSuccessCheck) ||
      (portability.portabilityMigration?.currentProvider &&
        checkOperator(
          portability.portabilityMigration.currentProvider,
          cmsOperatorsConfig?.operatorsconfiggroup?.elements,
          product.offerAvaiability
        ))
    ) {
      const taggingMobile = getTagging('e-sim option');
      trackWithNewDatalayer({
        event: ['checkout_step4.7'],
        event_label: 'e-sim option',
        opts: {
          ...taggingMobile?.opts,
          versatile_prop: findOperatorValue(portability.portabilityMigration?.currentProvider),
        },
        cartProduct: taggingMobile?.trackingProduct,
        pageProduct: taggingMobile?.pageProductsInfo,
        visitorTrackingOpts: getUserInfo(),
      });
    }
  }, [isAcceptSuccess]);

  // endregion

  // region API Management

  useEffect(() => {
    if (portabilityStatus?.isSuccessCheck) setHasAccepted(false);
  }, [portabilityStatus?.isSuccessCheck]);

  useEffect(() => {
    if (portabilityStatus?.isErrorCheck) {
      const goBackMigration = () => {
        setSlidesGoBack(3);
      };
      const error = errorMock(
        'checkPortInFeasibilityII',
        {
          status: '200',
          url: API.CHECK_IN_PORT,
          statusText: 'failure',
        },
        'failure',
        portabilityStatus?.errorMessage,
        'check portin failure',
        false,
        {
          body: {
            currentPlanType: portability?.portabilityMigration?.currentPlan || '',
            currentServiceProviderId: portability?.portabilityMigration?.currentProvider || '',
            currentServiceProviderIdName: portability?.portabilityMigration?.currentProvider || '',
            msisdn: portability.portabilityNumber || '',
            isSimAvailable: ICCID && ICCID?.trim() !== '' ? 'true' : 'false',
            transferRemainingBalance: String(portability?.portabilityMigration?.transferBalance),
            simCardNumber: ICCID?.trim() || '',
          },
          response: {
            ...portabilityStatus?.portInData,
          },
        }
      );
      switch (portabilityStatus?.errorMessage) {
        case ErrorCodes.NUMBER_ERROR:
          checkoutErrors?.showAndTrackError?.(
            genericError,
            goBackMigration,
            error,
            organizedDataForSim?.numberError?.title,
            organizedDataForSim?.numberError?.description,
            goBackMigration,
            organizedDataForSim?.numberError?.buttonLabel,
            () => openPopup(product?.genericErrorCallMeNow?.url || '', goBackMigration),
            product?.removeCTC ? undefined : product?.genericErrorCallMeNow?.label,
            isModalButtonSticky
          );
          break;
        case ErrorCodes.MIGRATION_CODE_ERROR:
          checkoutErrors?.showAndTrackError?.(
            genericError,
            goBackMigration,
            error,
            organizedDataForSim?.migrationCodeError?.title,
            organizedDataForSim?.migrationCodeError?.description,
            goBackMigration,
            organizedDataForSim?.migrationCodeError?.buttonLabel,
            () => openPopup(product?.genericErrorCallMeNow?.url || '', goBackMigration),
            product?.removeCTC ? undefined : product?.genericErrorCallMeNow?.label,
            isModalButtonSticky
          );
          break;
        default:
          checkoutErrors?.showAndTrackError?.(
            genericError,
            goBackMigration,
            error,
            organizedDataForSim?.genericsError?.title,
            organizedDataForSim?.genericsError?.description,
            goBackMigration,
            organizedDataForSim?.genericsError?.buttonLabel,
            () => openPopup(product?.genericErrorCallMeNow?.url || '', goBackMigration),
            product?.removeCTC ? undefined : product?.genericErrorCallMeNow?.label,
            isModalButtonSticky
          );
          break;
      }
    }
  }, [portabilityStatus?.isErrorCheck]);

  useEffect(() => {
    if (isAcceptError) {
      checkoutErrors?.showAndTrackError?.(
        genericError,
        redirectHomePage,
        acceptError,
        organizedDataForSim?.genericsError?.title,
        organizedDataForSim?.genericsError?.description,
        () => openPopup(product?.genericErrorCallMeNow?.url || '', redirectHomePage),
        product?.removeCTC ? undefined : product?.genericErrorCallMeNow?.label,
        undefined,
        undefined,
        isModalButtonSticky
      );
    }
  }, [isAcceptError]);

  useEffect(() => {
    if (enablerPortability) {
      setPortabilityStatus({
        ...portabilityStatus,
        isStartPortIn: true,
      });
    }
  }, [enablerPortability]);

  useEffect(() => {
    if (isAcceptSuccess) setEnablerPortability(true);
  }, [isAcceptSuccess]);

  useEffect(() => {
    handleOnChangeEnableGoNext && handleOnChangeEnableGoNext(!isAcceptLoading);
  }, [isAcceptLoading]);

  useEffect(() => {
    if (portability.portabilityWant && portability?.portabilityOtp?.isVerified) {
      if (
        portability.portabilityMigration?.currentProvider &&
        !checkOperator(
          portability.portabilityMigration.currentProvider,
          cmsOperatorsConfig?.operatorsconfiggroup?.elements,
          product.offerAvaiability
        )
      ) {
        const fallbackProduct = checkProductAvaiable(
          product,
          portability.portabilityMigration.currentProvider,
          allProducts,
          cmsOperatorsConfig?.operatorsconfiggroup?.elements
        );
        if (fallbackProduct !== undefined) {
          setAvaiableProduct(fallbackProduct);
          setIsFallbackModalOpen(true);
        } else {
          const organizedPopUp = organizeNoOfferPopUp(
            product?.digitalBundleConfig?.noOfferError,
            portability.portabilityMigration.currentProvider
          );
          setNoOfferConfig({
            title: organizedPopUp?.title || '',
            description: organizedPopUp?.description || '',
            buttonLabel: organizedPopUp?.buttonLabel || '',
            isOpen: true,
          });
          const trackObj: ITrackView = {
            event_name: 'page_error',
            event_category: 'error',
            page_error: `_${organizedPopUp?.title || 'Ops'}_${
              organizedPopUp?.buttonLabel || 'Ti richiamiamo noi'
            }`,
            page_type: 'error page',
            product_name: product?.slug || '',
          };
          trackView(trackObj);
        }
      } else setEnablerPortability(true);
    }
  }, []);

  // endregion

  return (
    <>
      {!portabilityStatus?.isLoadingCheck &&
        !isAcceptLoading &&
        !hasAccepted &&
        (!portability.portabilityWant ||
          (portability.portabilityMigration?.currentProvider &&
            checkOperator(
              portability.portabilityMigration.currentProvider,
              cmsOperatorsConfig?.operatorsconfiggroup?.elements,
              product.offerAvaiability
            ))) && <SelectionCard handleOnChangeEnableGoNext={handleOnChangeEnableGoNext} />}
      {(portabilityStatus?.isLoadingCheck || isAcceptLoading || hasAccepted) && (
        <LoadingCard product={avaiableProduct} hasAcceptedFallback={hasAccepted} />
      )}
      <FallbackModal
        isOpen={isFallbackModalOpen}
        handleClose={handleClose}
        handleAccept={handleAccept}
        offerData={organizeNewProductData(
          avaiableProduct,
          portability.portabilityMigration?.currentProvider
        )}
        buildTrackingObject={buildTrackingObject}
        product={avaiableProduct}
        taggingPageName="offer exchange"
      />
      <NoOfferModal
        {...noOfferConfig}
        onClose={redirectHomePage}
        onButtonClick={() => {
          volaModalManager.handleVolaModal({
            url:
              product?.digitalBundleConfig?.noOfferError?.url || product?.callMeNowHelp?.url || '',
            cmsClickToCallData: { ...volaClickToCallData },
            taggingOption: {
              product_target_segment: 'consumer',
              event_category: 'sales',
              page_subsection: 'checkout',
              versatile_prop: 'new ctc',
            },
            pageProduct: SoftManagerService(queryClient).getPageProduct(product),
            onClose: redirectHomePage,
          });
        }}
      />
    </>
  );
};

export default SimTypeSelectionCard;
