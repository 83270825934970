import {
  CONSUMER_CMS_SHOPPING_CART,
  IPersonalInfoCmsMobile,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { API } from '@vfit/shared/data-access';
import { useTracking } from '@vfit/shared/data-access';
import { ListCard } from '@vfit/consumer/components';
import { organizeRecognitionEsim } from './recognitionEsim.utils';
import { IRecognitionEsim } from './recognitionEsim.models';
import { getUserInfo, getTagging } from '../../../../checkout.utils';

const RecognitionEsim = ({ isMNP }: IRecognitionEsim) => {
  const dataFromCms = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_PERSONAL_DATA_MOBILE
  ) as IPersonalInfoCmsMobile;
  const recognitionSimData = organizeRecognitionEsim(dataFromCms);
  const list = {
    title: recognitionSimData?.list?.title,
    items: isMNP ? recognitionSimData?.list?.portability : recognitionSimData?.list?.items,
  };

  const taggingMobile = getTagging(isMNP ? 'mnp' : 'new sim');

  useTracking({
    event: ['checkout_step4.8'],
    event_label: 'VR: Video Recognition',
    opts: {
      ...taggingMobile?.opts,
      page_subsection: 'e-sim',
    },
    cartProduct: taggingMobile?.trackingProduct,
    pageProduct: taggingMobile?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });

  return (
    <div>
      <ListCard data={recognitionSimData?.data} list={list} />
    </div>
  );
};

export default RecognitionEsim;
