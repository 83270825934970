import React, { useEffect } from 'react';
import {
  CONSUMER_CMS_SHOPPING_CART,
  useCmsConfig,
  ITermsAndCondition,
  BYPASS_CALL_API_SIA,
  productSpecificError,
  errorMock,
  IGenericErrorCMS,
} from '@vfit/consumer/data-access';
import {
  API,
  openPopup,
  requireStaticImageAtomComponent,
  setTrackLink,
  trackLink,
} from '@vfit/shared/data-access';
import { useTracking } from '@vfit/shared/data-access';
import { ShadowsGroup } from '@vfit/shared/components';
import { Player } from '@lottiefiles/react-lottie-player';
import { organizeTermsCard } from './termsCard.utils';
import { ID_FLOWS } from '../../checkout.constants';
import { useCheckout } from '../../../iBuyMobile.context';
import { ICheckoutData } from '../../checkout.models';
import { getUserInfo, getTagging } from '../../checkout.utils';
import PostAddress from './components/PostAddress/postAddress';
import { Container, TextLoaderEmpty } from './components/PostAddress/postAddress.style';
import TermsAndConditions from './components/TermsAndConditions/termsAndConditions';

const TermsCard = (props: ICheckoutData) => {
  const { handleOnChangeEnableGoNext, handleGoPrevSlide } = props;
  const {
    checkoutErrors,
    termsAndConditions,
    isNewShippingAddress,
    deliveryAdvance,
    isModalButtonSticky,
    product,
    setIsDisabledBackButton,
    setTermsAndConditions,
    setCurrentStepKey,
  } = useCheckout();
  const { buttonGenericError, urlGenericErrorCallMeNow } = productSpecificError(product);
  const OPTION_TO_RECONSIDER = 'no';

  const cmsData = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_TERMS_AND_CONDITIONS_MOBILE
  ) as ITermsAndCondition;
  const genericError = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GENERIC_ERROR_MOBILE
  ) as IGenericErrorCMS;
  const editorialLabels = organizeTermsCard(cmsData);

  const { titleModal, messageModal } = editorialLabels;

  const taggingMobile = getTagging('contract terms');

  const { trackView } = useTracking({
    event: ['checkout_step7'],
    event_label: 'terms and conditions',
    opts: taggingMobile?.opts,
    cartProduct: taggingMobile?.trackingProduct,
    pageProduct: taggingMobile?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'terms and conditions');

  const handleSetNext = (checked: boolean) => {
    setTermsAndConditions({
      ...termsAndConditions,
      consensus: checked,
      optionToReconsider: OPTION_TO_RECONSIDER,
    });
    trackLink(checked ? 'checkbox on' : 'checkbox off');
  };

  useEffect(() => {
    if (handleOnChangeEnableGoNext) {
      if (termsAndConditions.isSuccessTermsAndConditions && termsAndConditions.consensus) {
        handleOnChangeEnableGoNext(termsAndConditions.isSuccessTermsAndConditions);
      } else {
        handleOnChangeEnableGoNext(false);
        if (
          !termsAndConditions?.isLoadingTermsAndConditions &&
          !termsAndConditions?.isSuccessTermsAndConditions &&
          termsAndConditions?.isErrorTermsAndConditions
        ) {
          checkoutErrors?.showAndTrackError?.(
            genericError,
            handleGoPrevSlide,
            errorMock('termsAndConditions', {
              url: 'getTermsAndConditions',
              status: '500',
              statusText: '500',
            }),
            titleModal,
            messageModal,
            handleGoPrevSlide,
            'Chiudi',
            () => openPopup(urlGenericErrorCallMeNow, handleGoPrevSlide),
            product?.removeCTC ? undefined : buttonGenericError,
            isModalButtonSticky,
            true
          );
        }
      }
    }
  }, [termsAndConditions]);

  useEffect(() => {
    setIsDisabledBackButton(deliveryAdvance?.isLoading);
  }, [deliveryAdvance]);

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.TERMS_AND_CONDITIONS);
    localStorage.removeItem(BYPASS_CALL_API_SIA);
  }, []);

  if (deliveryAdvance?.isLoading || termsAndConditions.isLoadingTermsAndConditions) {
    if (product?.loaders?.yourAddressCard) {
      return (
        <Container>
          <TextLoaderEmpty />
          <Player
            src={requireStaticImageAtomComponent(product.loaders.yourAddressCard).src}
            className="player"
            loop
            autoplay
          />
        </Container>
      );
    }
    return <ShadowsGroup quantity={2} heights={90} />;
  }

  return (
    <>
      {isNewShippingAddress && <PostAddress {...props} />}
      {!isNewShippingAddress && (
        <TermsAndConditions
          setNextAble={handleSetNext}
          isLoading={false}
          value={termsAndConditions}
          pdfContractSummary={product?.pdfContractSummary}
          editorialLabels={editorialLabels}
        />
      )}
    </>
  );
};

export default TermsCard;
