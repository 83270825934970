import { useEffect } from 'react';
import {
  CONSUMER_CMS_SHOPPING_CART,
  useCmsConfig,
  useMultiPlayOfferingConfiguration,
  IPortabilityCMS,
  productSpecificError,
  IGenericErrorCMS,
} from '@vfit/consumer/data-access';
import { API, openPopup } from '@vfit/shared/data-access';
import { useTracking } from '@vfit/shared/data-access';
import { ICommonData } from '@vfit/shared/models';
import { useQueryClient } from 'react-query';
import {
  organizePortabilityError,
  organizePortabilityNumberCard,
} from './portabilityNumberCard.utils';
import PortabilityNumber from './components/PortabilityNumber/portabilityNumber';
import { getTagging, getUserInfo } from '../../checkout.utils';
import { useCheckout } from '../../../iBuyFixed.context';
import { ID_FLOWS } from '../../checkout.constants';
import { retrieveProduct } from '../../../iBuyFixed.utils';

const PortabilityNumberCard = ({ handleOnChangeEnableGoNext, handleGoPrevSlide }: ICommonData) => {
  const { checkoutErrors, portability, isModalButtonSticky, setPortability, setCurrentStepKey } =
    useCheckout();
  const queryClient = useQueryClient();

  const productTracking = retrieveProduct();
  const { buttonGenericError, urlGenericErrorCallMeNow } = productSpecificError(productTracking);

  const taggingFixed = getTagging('portability', 'fixed', queryClient);

  useTracking({
    event: ['checkout_step4.1'],
    event_label: 'number portability',
    opts: taggingFixed.opts,
    cartProduct: taggingFixed.trackingProduct,
    pageProduct: taggingFixed?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });

  const portabilityNumberCardCMS = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_PORTABILITY
  ) as IPortabilityCMS;
  const genericError = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;
  const { isLoading, isError } = useMultiPlayOfferingConfiguration(
    portabilityNumberCardCMS?.portability?.operatorList
  );
  const { title, description, label } = organizePortabilityNumberCard(portabilityNumberCardCMS);
  const { titleError, messageError } = organizePortabilityError(portabilityNumberCardCMS);

  const checkPortabilityValidation = () => {
    if (portability?.portabilityNumber) {
      const isMatches = portability.portabilityNumber.match(/^0\d{6,9}$/);
      handleOnChangeEnableGoNext?.(!!isMatches);
    } else {
      handleOnChangeEnableGoNext?.(false);
    }
  };

  useEffect(() => {
    checkPortabilityValidation();
  }, [portability]);

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.PORTABILITY_NUMBER);
  }, []);

  useEffect(() => {
    if (isError) {
      const action = () => {
        if (handleGoPrevSlide) handleGoPrevSlide();
      };
      checkoutErrors?.showAndTrackError?.(
        genericError,
        () => action(),
        undefined,
        titleError,
        messageError,
        () => () => openPopup(urlGenericErrorCallMeNow, action),
        buttonGenericError,
        undefined,
        undefined,
        isModalButtonSticky
      );
    }
  }, [isError]);

  const onChangeData = (telephoneNumber: string) => {
    setPortability({
      ...portability,
      portabilityNumber: telephoneNumber || '',
      portabilityInfo: '',
    });
  };

  return (
    <PortabilityNumber
      title={title}
      description={description}
      label={label}
      value={portability.portabilityNumber}
      onChangeData={onChangeData}
      isLoading={isLoading}
    />
  );
};

export default PortabilityNumberCard;
