import { useEffect } from 'react';
import { API, setTrackLink, setTrackView, TechnologyCategories } from '@vfit/shared/data-access';
import {
  CONSUMER_CMS_SHOPPING_CART,
  errorMock,
  IDeliveryInformation,
  IGenericErrorCMS,
  IPortabilityCMS,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { LoggerInstance, useTracking } from '@vfit/shared/data-access';
import { ICommonData, IEditAddressCmsConfig } from '@vfit/shared/models';
import { useQueryClient } from 'react-query';
import { useCheckout } from '../../../iBuyFixed.context';
import { getUserInfo, getTagging } from '../../checkout.utils';
import { ID_FLOWS } from '../../checkout.constants';
import { getDataOrganized } from './yourAddressCard.utils';
import { getTechnology } from '../../../ShoppingCart/shoppingCart.utils';
import YourAddress from './components/YourAddress/yourAddress';
import LoadingCard from '../LoadingCard/loadingCard';

const YourAddressCard = ({ handleOnChangeEnableGoNext }: ICommonData) => {
  const {
    owningData: { owningIndividual },
    checkoutErrors,
    termsAndConditions,
    installationAddress,
    shippingAddress,
    billingAddress,
    portability,
    portabilityStatus,
    setIsDisabledBackButton,
    setSlidesGoBack,
    setPortability,
    setPortabilityStatus,
    setCurrentStepKey,
    setTermsAndConditions,
  } = useCheckout();
  const technology = getTechnology();
  const showTechAddress = !(
    technology?.mappedCategory === TechnologyCategories.FTTH ||
    technology?.mappedCategory === TechnologyCategories.FTTHTIM
  );
  const queryClient = useQueryClient();
  const genericError = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;
  const deliveryInformation = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_DELIVERY_INFORMATION
  ) as IDeliveryInformation;
  const editAddressData = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_EDIT_ADDRESS
  ) as IEditAddressCmsConfig;
  const portabilityCardCms = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_PORTABILITY
  ) as IPortabilityCMS;

  const tagging = getTagging('address confirmation', 'fixed', queryClient);

  const addressCmsData = getDataOrganized(
    deliveryInformation,
    owningIndividual,
    installationAddress,
    shippingAddress,
    billingAddress,
    editAddressData,
    showTechAddress
  );

  const { trackView } = useTracking({
    event: ['checkout_step5'],
    event_label: 'address confirmation',
    opts: tagging.opts,
    cartProduct: tagging.trackingProduct,
    pageProduct: tagging?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'address confirmation');
  setTrackView(trackView);

  useEffect(() => {
    if (portabilityStatus?.isErrorCheck) {
      // TODO add error in portability hook
      const isTelInt = portability?.portabilityInfo?.toLowerCase() === 'telint';
      LoggerInstance.log('Error: portability_error');
      checkoutErrors?.showAndTrackError?.(
        genericError,
        () => {
          setSlidesGoBack(!isTelInt ? 4 : 5);
          setPortabilityStatus({
            ...portabilityStatus,
            isLoading: false,
            isSuccess: false,
            isError: false,
          });
          setPortability({
            ...portability,
          });
        },
        errorMock(
          'portability',
          {
            url: 'checkPortInFeasibilityII',
            status: '200',
            statusText: portabilityStatus?.errorMessage || '',
          },
          portabilityStatus?.errorMessage,
          portabilityStatus?.errorMessage
        ),
        portabilityCardCms?.portability?.errorOnPortability?.title || 'Ops',
        portabilityCardCms?.portability?.errorOnPortability?.message ||
          'Abbiamo avuto un problema durante la portabilità'
      );
    }
  }, [portability]);

  useEffect(() => {
    setIsDisabledBackButton(!!portabilityStatus?.isLoadingCheck);
    handleOnChangeEnableGoNext?.(!portabilityStatus?.isLoadingCheck);
  }, [portabilityStatus?.isLoadingCheck]);

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.DELIVERY_ADDRESS);
    setTermsAndConditions({
      ...termsAndConditions,
      preConsensus: true,
    });
  }, []);

  if (portabilityStatus?.isLoadingCheck) {
    return <LoadingCard />;
  }

  return <YourAddress {...addressCmsData} showTechAddress={showTechAddress} />;
};

export default YourAddressCard;
