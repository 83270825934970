import { useEffect } from 'react';
import {
  CONSUMER_CMS_SHOPPING_CART,
  IGenericErrorCMS,
  SELECTED_PAYMENT,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { EMethodCode, ICommonData } from '@vfit/shared/models';
import { API } from '@vfit/shared/data-access';
import { useCheckout } from '../../../iBuyFixed.context';
import UpfrontPayment from './UpfrontPayment/upfrontPayment';
import LoadingCard from '../LoadingCard/loadingCard';
import { DEFAULT_AUTHORIZATION_ASYNC_INFO } from '../../../iBuyFixed.context.data';

const UpfrontPaymentCard = (props: ICommonData) => {
  const {
    checkoutErrors,
    authorizationAsyncInfo,
    isModalButtonSticky,
    setAuthorizationAsyncInfo,
    setIsDisabledBackButton,
    setIsStartAuthorizationAsyncInfo,
  } = useCheckout();
  const { handleGoPrevSlide } = props;
  const lastSelectedType = localStorage.getItem(SELECTED_PAYMENT);
  const isWallet = lastSelectedType?.toLowerCase() === EMethodCode.WALLET.toLowerCase();
  const isLoading = isWallet
    ? false
    : !(
        authorizationAsyncInfo?.payMean?.isSuccess &&
        authorizationAsyncInfo?.authorization?.isSuccess
      );

  const genericError = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;
  const { error: errorAuthorization, isError: isErrorAuthorization } =
    authorizationAsyncInfo.authorization;

  const checkAuthorization = () => {
    if (!isWallet) setIsStartAuthorizationAsyncInfo(true);
  };

  useEffect(() => {
    if (isErrorAuthorization) {
      checkoutErrors?.showAndTrackError?.(
        genericError,
        () => {
          setAuthorizationAsyncInfo({ ...DEFAULT_AUTHORIZATION_ASYNC_INFO });
          handleGoPrevSlide?.();
        },
        errorAuthorization || undefined,
        'Ops',
        'Si è verificato un errore durante il recupero dei pagamenti. Riprovare in seguito',
        () => {
          setAuthorizationAsyncInfo({ ...DEFAULT_AUTHORIZATION_ASYNC_INFO });
          handleGoPrevSlide?.();
        },
        'Chiudi',
        undefined,
        undefined,
        isModalButtonSticky,
        true
      );
    }
  }, [authorizationAsyncInfo]);

  useEffect(() => {
    setIsDisabledBackButton(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setAuthorizationAsyncInfo({ ...DEFAULT_AUTHORIZATION_ASYNC_INFO });
    // this timeout is because between SIA and amdocs there
    // is a delay and the authorization api goes to 500 if made too early
    setTimeout(() => {
      checkAuthorization();
    }, 10000);
  }, []);

  if (isLoading) {
    return <LoadingCard />;
  }

  return <UpfrontPayment {...props} />;
};

export default UpfrontPaymentCard;
