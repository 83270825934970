import { useEffect, useState } from 'react';
import { API, openPopup, setTrackLink, useTracking } from '@vfit/shared/data-access';
import {
  EMethodCode,
  IAvailablePaymentMethods,
  ISIAFlowType,
  IUpdateProductPriceType,
  IWarningInfo,
} from '@vfit/shared/models';
import {
  CONSUMER_CMS_SHOPPING_CART,
  getCurrentUserType,
  IGenericErrorCMS,
  IPayments,
  IS_AUTOMATIC_RECHARGE_SELECTED,
  IUserType,
  productSpecificError,
  SELECTED_PAYMENT,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { WarningInfo } from '@vfit/shared/components';
import { useCheckout } from '../../../../../iBuyMobile.context';
import { getTagging, getUserInfo } from '../../../../checkout.utils';
import { organizePaymentMethodError } from '../../recurrentPaymentCard.utils';
import { ID_FLOWS } from '../../../../checkout.constants';
import { CONTEXT_KEY } from '../../../../../iBuyMobile.context.data';
import { ICheckoutData } from '../../../../checkout.models';
import { updateProductPrice } from '../../../../../ShoppingCart/shoppingCart.utils';
import { useUpdatePaymentMethodFlow } from '../../../../hooks/UseUpdatePaymentMethodFlow/useUpdatePaymentMethodFlow';
import AutoWalletSubmitCard from './components/AutoWalletSubmitCard/autoWalletSubmitCard';

const AutoWalletRecurrentPaymentCard = (props: ICheckoutData) => {
  const { handleGoPrevSlide } = props || {};
  const {
    checkoutErrors,
    availablePaymentMethods,
    customerFlow,
    isModalButtonSticky,
    product,
    setProduct,
    setCurrentStepKey,
    setAvailablePaymentMethods,
    setIsLastCard,
    setHideStickyBar,
    setUpfrontPayment,
    setIsDisabledBackButton,
    setIsPreselectedCard,
  } = useCheckout();
  const genericError = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GENERIC_ERROR_MOBILE
  ) as IGenericErrorCMS;
  const {
    paymentsmobile: { warningPriceChange, warningWaiting },
  } = useCmsConfig(CONSUMER_CMS_SHOPPING_CART, API.CMS_GET_PAYMENTS_MOBILE) as IPayments;
  const cmsWarningWaitingOffer: IWarningInfo = {
    title: warningWaiting?.title || warningPriceChange?.title || '',
    subtitle: warningWaiting?.subtitle || warningPriceChange?.subtitle || '',
    description: warningWaiting?.description || warningPriceChange?.description || '',
    lottie: product?.loaders?.choosePayment || '',
  };
  const { buttonPaymentError, urlPaymentErrorCallMeNow } = productSpecificError(product);
  const paymentCMS = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_PAYMENTS_MOBILE
  ) as IPayments;
  const {
    messageSelectionPaymentError,
    titleSelectionPaymentError,
    buttonLabelSelectionPayment,
    iconSelectionPayment,
    titleSelectedPaymentError,
    messageSelectedPaymentError,
    buttonLabelSelectedPayment,
    iconSelectedPayment,
  } = organizePaymentMethodError(paymentCMS);
  const taggingMobile = getTagging('add payment method');
  const [enablePut, setEnablePut] = useState(false);
  const paymentData = customerFlow?.payment?.data;
  const paymentIsLoading = customerFlow?.payment?.isLoading;
  const paymentIsError = customerFlow?.payment?.isError;
  const {
    isLoading: isLoadingUpdatePaymentMethod,
    isSuccess: isSuccessUpdatePaymentMethod,
    isError: isErrorUpdatePaymentMethod,
    error: errorUpdatePaymentMethod,
  } = useUpdatePaymentMethodFlow(
    paymentData?.orderPayment?.orderItemPayments?.[0]?.orderItemOnBillPayment
      ?.billingOfferIdForChangePaymentMethod || [''],
    paymentData?.orderPayment?.orderItemPayments?.[0]?.orderActionId || '',
    enablePut,
    availablePaymentMethods?.find(
      (availablePayment) => availablePayment.methodCode === EMethodCode.WALLET
    ),
    ISIAFlowType.RECURRENT
  );

  const { trackView } = useTracking({
    event:
      getCurrentUserType() === IUserType.PROSPECT_USER
        ? ['checkout_step9.0']
        : ['checkout_step9.1'],
    event_label: 'add payment method',
    opts: taggingMobile?.opts,
    cartProduct: taggingMobile?.trackingProduct,
    pageProduct: taggingMobile?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'add payment method');

  useEffect(() => {
    const resetPaymentData = () => {
      const availablePaymentMethodsPrev: Array<IAvailablePaymentMethods> =
        paymentData?.orderPayment.orderOnBillPayment?.availablePaymentMethods?.map((method) => ({
          ...method,
          selection: false,
        })) || [];

      setAvailablePaymentMethods(availablePaymentMethodsPrev || []);
    };

    if (!paymentIsLoading && !paymentIsError && paymentData) {
      const availablePaymentMethodsPrev: Array<IAvailablePaymentMethods> =
        paymentData?.orderPayment.orderOnBillPayment?.availablePaymentMethods?.map(
          (method, index) => ({
            ...method,
            selection: availablePaymentMethods[index]?.selection,
          })
        ) || [];
      setAvailablePaymentMethods(availablePaymentMethodsPrev || []);
    }
    if (!paymentIsLoading && paymentIsError) {
      const action = () => {
        if (handleGoPrevSlide) handleGoPrevSlide();
      };
      resetPaymentData();
      setHideStickyBar(false);
      checkoutErrors?.showAndTrackError?.(
        genericError,
        action,
        customerFlow?.paymean?.error || undefined,
        titleSelectedPaymentError,
        messageSelectedPaymentError,
        action,
        buttonLabelSelectedPayment || buttonPaymentError,
        () => openPopup(urlPaymentErrorCallMeNow || '', action),
        product?.removeCTC ? undefined : product?.genericErrorCallMeNow?.label || '', // FIX:
        isModalButtonSticky,
        true,
        false,
        false,
        iconSelectedPayment || ''
      );
    }
  }, [paymentData, paymentIsError, paymentIsLoading]);

  useEffect(() => {
    const resetPaymentData = () => {
      const availablePaymentMethodsPrev: Array<IAvailablePaymentMethods> =
        paymentData?.orderPayment.orderOnBillPayment?.availablePaymentMethods?.map((method) => ({
          ...method,
          selection: false,
        })) || [];

      setAvailablePaymentMethods(availablePaymentMethodsPrev || []);
    };
    if (isLoadingUpdatePaymentMethod && localStorage.getItem(CONTEXT_KEY)) {
      localStorage.removeItem(CONTEXT_KEY);
    }
    if (
      isSuccessUpdatePaymentMethod &&
      !isLoadingUpdatePaymentMethod &&
      !isErrorUpdatePaymentMethod
    ) {
      setProduct({
        ...product,
        ...updateProductPrice(product, IUpdateProductPriceType.RESET),
      });
    }
    if (isErrorUpdatePaymentMethod && !isLoadingUpdatePaymentMethod) {
      const action = () => {
        if (handleGoPrevSlide) handleGoPrevSlide();
      };
      resetPaymentData();
      setHideStickyBar(false);
      checkoutErrors?.showAndTrackError?.(
        genericError,
        action,
        errorUpdatePaymentMethod || undefined,
        titleSelectionPaymentError,
        messageSelectionPaymentError,
        action,
        buttonLabelSelectionPayment || buttonPaymentError,
        () => openPopup(urlPaymentErrorCallMeNow || '', action),
        product?.removeCTC ? undefined : product?.genericErrorCallMeNow?.label || '',
        isModalButtonSticky,
        true,
        false,
        false,
        iconSelectionPayment || ''
      );
    }
  }, [isSuccessUpdatePaymentMethod, isLoadingUpdatePaymentMethod, isErrorUpdatePaymentMethod]);

  useEffect(() => {
    if (enablePut) setEnablePut(false);
  }, [enablePut]);

  useEffect(() => {
    // reset for back SIA
    window.history.pushState(null, '', window.location.pathname);
    setCurrentStepKey(ID_FLOWS.RECURRENT_PAYMENT_CARD);
    setIsPreselectedCard(false);
    setUpfrontPayment(undefined);
    localStorage.setItem(SELECTED_PAYMENT, EMethodCode.WALLET);
    localStorage.removeItem(IS_AUTOMATIC_RECHARGE_SELECTED);
    setIsLastCard(true);
    setEnablePut(true);
    setIsDisabledBackButton(true);
    setHideStickyBar(true);
  }, []);

  return (
    <>
      <WarningInfo {...cmsWarningWaitingOffer} />
      {!enablePut &&
        isSuccessUpdatePaymentMethod &&
        !isLoadingUpdatePaymentMethod &&
        !isErrorUpdatePaymentMethod && <AutoWalletSubmitCard />}
    </>
  );
};

export default AutoWalletRecurrentPaymentCard;
