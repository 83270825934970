import { useEffect } from 'react';
import {
  API,
  defineYupSchema,
  errorMock,
  openPopup,
  regex,
  setTrackLink,
  useTracking,
} from '@vfit/shared/data-access';
import {
  CONSUMER_CMS_SHOPPING_CART,
  createPayloadMobile,
  IGenericErrorCMS,
  IProduct,
  IWinBackCMS,
  productSpecificError,
  useCmsConfig,
  useGetShoppingCartSilentUpdate,
  useShoppingCartMutation,
} from '@vfit/consumer/data-access';
import { useCheckout } from '../../../iBuyMobile.context';
import PortabilityNumber from './components/PortabilityNumber/portabilityNumber';
import { ID_FLOWS } from '../../checkout.constants';
import { ICheckoutData } from '../../checkout.models';
import { getUserInfo, getTagging } from '../../checkout.utils';

const WinbackLegacyNumberCard = ({
  handleOnChangeEnableGoNext,
  handleGoPrevSlide,
}: ICheckoutData) => {
  const { winbacknumber: cmsData } = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_WINBACK_LEGACY_DATA
  ) as IWinBackCMS;
  const { title, description, placeholder, disclaimer } = cmsData;

  const genericError = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GENERIC_ERROR_MOBILE
  ) as IGenericErrorCMS;

  const {
    product,
    setCurrentStepKey,
    winBackNumber,
    setWinBackNumber,
    isModalButtonSticky,
    checkoutErrors,
  } = useCheckout();

  const { urlGenericErrorCallMeNow } = productSpecificError(product);

  const {
    data: shoppingCartResponse,
    mutate: createShoppingCartMutation,
    isLoading: isLoadingShoppingCart,
    isSuccess: isSuccessShoppingCart,
    isError: isErrorShoppingCart,
  } = useShoppingCartMutation();

  const {
    isLoading: isLoadingGetShoppingCart,
    isSuccess: isSuccessGetShoppingCart,
    isError: isErrorGetShoppingCart,
    error: shoppingCartError,
  } = useGetShoppingCartSilentUpdate(shoppingCartResponse?.id, { enabled: isSuccessShoppingCart });

  const startCartCreation = (product: IProduct) => {
    const payload = createPayloadMobile(
      product?.offerId,
      product?.planId.toString(),
      false,
      '',
      true,
      true
    );
    if (payload) createShoppingCartMutation(payload);
  };

  const actionPopup = () => {
    openPopup(urlGenericErrorCallMeNow, () => {
      handleGoPrevSlide?.();
    });
  };

  const taggingMobile = getTagging('winback');

  const { trackView } = useTracking({
    event: ['view'],
    event_label: 'Verify ID',
    opts: {
      event_category: ['sales', 'journey'],
      journey_name: 'winback',
      journey_type: 'journey',
      page_section: 'campaign',
      page_subsection: 'check number',
      page_type: 'Verify ID',
    },
    cartProduct: taggingMobile?.trackingProduct,
    pageProduct: taggingMobile?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'verify ID');

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.WINBACK_NUMBER);
  }, []);

  useEffect(() => {
    const isValid = !!winBackNumber?.match(regex['mobilePhoneNumber']);
    handleOnChangeEnableGoNext?.(isValid);
  }, [winBackNumber]);

  const onChangeData = (telephoneNumber: string) => {
    setWinBackNumber(telephoneNumber);
  };

  useEffect(() => {
    if (isErrorShoppingCart || isErrorGetShoppingCart) {
      checkoutErrors?.showAndTrackError?.(
        genericError,
        () => {},
        errorMock(
          'shoppingCart',
          shoppingCartError || { url: API.SHOPPING_CART, status: '500', statusText: 'KO' }
        ),
        genericError.genericerror.title,
        genericError.genericerror.description,
        actionPopup,
        genericError.genericerror.buttonLabel,
        actionPopup,
        'buttonLabel',
        isModalButtonSticky,
        true
      );
    }
  }, [isErrorShoppingCart, isErrorGetShoppingCart]);

  useEffect(() => {
    if (product && !isLoadingShoppingCart && !isSuccessShoppingCart && !isSuccessGetShoppingCart) {
      startCartCreation(product);
    }
  }, [product]);

  return (
    <PortabilityNumber
      title={title}
      description={description}
      label={placeholder}
      value={winBackNumber}
      disclaimer={disclaimer}
      onChangeData={onChangeData}
      isLoading={isLoadingShoppingCart || isLoadingGetShoppingCart || !isSuccessGetShoppingCart}
      disabled={false}
      schema={defineYupSchema('phoneNumber')}
    />
  );
};

export default WinbackLegacyNumberCard;

