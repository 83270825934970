import { useEffect, useState } from 'react';
import { API, setTrackLink } from '@vfit/shared/data-access';
import {
  CONSUMER_CMS_SHOPPING_CART,
  useCmsConfig,
  ITermsAndCondition,
  usePrivacyProfile,
} from '@vfit/consumer/data-access';
import { IPartyPrivacyItem } from '@vfit/shared/models';
import { useConsentsFlow } from '@vfit/consumer/hooks';
import { resetData, useTracking } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { Consents, Skeleton } from '@vfit/shared/components';
import {
  organizeTermsAndConditionConsentCard,
  organizeTermsAndConditionConsents,
} from './consentCard.utils';
import { ICheckoutData } from '../../checkout.models';
import { useCheckout } from '../../../iBuyMobile.context';
import { getUserInfo, getTagging } from '../../checkout.utils';
import { ID_FLOWS } from '../../checkout.constants';
import { skeletonShape } from './consentCard.style';

const ConsentCard = ({ handleOnChangeEnableGoNext, handleGoNextSlide }: ICheckoutData) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { consents, setConsents, setCurrentStepKey } = useCheckout();
  const queryClient = useQueryClient();
  const { partyPrivacyItems } = useConsentsFlow();
  const privacyProfile = usePrivacyProfile();

  const taggingMobile = getTagging('consents');
  const { trackView } = useTracking({
    event: ['checkout_step8'],
    event_label: 'consents',
    opts: taggingMobile?.opts,
    cartProduct: taggingMobile?.trackingProduct,
    pageProduct: taggingMobile?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'consents');

  const consentsCms = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_TERMS_AND_CONDITIONS_MOBILE
  ) as ITermsAndCondition;
  const { title, subtitle, description, actionText } =
    organizeTermsAndConditionConsentCard(consentsCms);

  const onUpdateConsents = (updatedConsents: IPartyPrivacyItem[], goNext: boolean) => {
    setConsents({ partyPrivacyItems: updatedConsents });
    privacyProfile.mutate(updatedConsents);
    if (handleGoNextSlide && goNext) handleGoNextSlide();
  };

  useEffect(() => {
    if (consents.partyPrivacyItems.length === 0) {
      setConsents({ partyPrivacyItems });
    }
  }, [consents, partyPrivacyItems]);

  useEffect(() => {
    // resetting payment data
    resetData(queryClient, ['authorize', 'reserveEquipment']);
    setCurrentStepKey(ID_FLOWS.CONSENTS);
    if (handleOnChangeEnableGoNext) {
      handleOnChangeEnableGoNext(true);
    }
  }, []);

  useEffect(() => {
    if (partyPrivacyItems.length > 0) {
      setIsLoading(false);
    }
  }, [partyPrivacyItems]);

  return (
    <>
      {isLoading && <Skeleton {...skeletonShape} />}
      {!isLoading && (
        <Consents
          title={title}
          description={description}
          subtitle={subtitle}
          partyPrivacyItems={consents.partyPrivacyItems}
          actionText={actionText}
          onUpdateConsents={onUpdateConsents}
          config={{ ...organizeTermsAndConditionConsents(consentsCms) }}
          trackView={trackView}
        />
      )}
    </>
  );
};

export default ConsentCard;
