import { useEffect, useState } from 'react';
import {
  CONSUMER_CMS_SHOPPING_CART,
  getCurrentUserType,
  handleFirstCharacterOfSentence,
  IBillingCard,
  IUserType,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { API, setTrackLink, trackLink } from '@vfit/shared/data-access';
import { ICommonData, IOptionalText } from '@vfit/shared/models';
import { useTracking } from '@vfit/shared/data-access';
import { CheckboxCards } from '@vfit/consumer/components';
import { VfModal } from '@vfit/shared/components';
import { useQueryClient } from 'react-query';
import { organizeBillingInvoiceCard, useGetUpdatedBilling } from './chooseInvoice.utils';
import { InvoiceType } from './chooseInvoice.models';
import { getTagging, getUserInfo, isRecognizedUser } from '../../../../checkout.utils';
import { useCheckout } from '../../../../../iBuyFixed.context';
import { ID_FLOWS } from '../../../../checkout.constants';
import { getLocalAddress } from '../../../../../ShoppingCart/shoppingCart.utils';
import EditEmailModal from '../EditEmailModal/editEmailModal';

const ChooseInvoice = ({ handleOnChangeEnableGoNext }: ICommonData) => {
  const {
    owningData: { owningIndividual },
    billingAddress,
    invoice,
    billingEmail,
    billingReplace: { billDelivery },
    setInvoice,
    setCurrentStepKey,
    setBillingEmail,
  } = useCheckout();
  const queryClient = useQueryClient();
  const tagging = getTagging('billing', 'fixed', queryClient);
  const {
    isSuccess: isBillDeliverySuccess,
    isLoading: isBillDeliveryLoading,
    isError: isBillDeliveryError,
  } = billDelivery;
  const {
    handleRefetch,
    isSuccess: isRefetchSuccess,
    isLoading: isRefetchLoading,
    isError: isRefetchError,
  } = useGetUpdatedBilling();

  const billingCheckboxProps = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_BILLING_INFORMATION
  ) as IBillingCard;
  const { card, email } = billingCheckboxProps.billinginformation;

  const { trackView } = useTracking({
    event: ['checkout_step6'],
    event_label: 'billing',
    opts: tagging.opts,
    cartProduct: tagging.trackingProduct,
    pageProduct: tagging?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView);

  const addressData = getLocalAddress();
  const [selectedItem, setSelectedItem] = useState<IOptionalText>();
  const [isOpen, setIsOpen] = useState(false);
  const [enableRefetch, setEnableRefetch] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(true);
    trackLink(card?.choice?.option2?.linkAddressLabel);
  };

  const handleSubmitNewEmail = (newEmail: string) => {
    setBillingEmail(newEmail);
    trackLink(email?.buttonLabel);
  };

  useEffect(() => {
    handleRefetch(enableRefetch);
    setEnableRefetch(false);
  }, [enableRefetch]);

  useEffect(() => {
    if (isBillDeliverySuccess && isOpen) {
      setEnableRefetch(true);
      if (isRefetchSuccess) {
        setIsOpen(false);
      }
    }
  }, [isBillDeliverySuccess, isRefetchSuccess]);

  const address =
    billingAddress.street === ''
      ? handleFirstCharacterOfSentence(addressData?.street || '').concat(
          ' ',
          addressData?.streetNumber || '',
          ', ',
          addressData?.postalCode || '',
          ' ',
          handleFirstCharacterOfSentence(addressData?.city || '')
        )
      : handleFirstCharacterOfSentence(billingAddress.street).concat(
          ' ',
          billingAddress.streetNumber,
          ', ',
          billingAddress.postalCode,
          ' ',
          handleFirstCharacterOfSentence(billingAddress.city)
        );

  const { title, description, items } = organizeBillingInvoiceCard(
    billingCheckboxProps,
    address,
    billingEmail ||
      (owningIndividual?.contactMedium && owningIndividual?.contactMedium[0]?.emailAddress),
    handleOpenModal
  );

  const handleSelect = (item: IOptionalText | undefined) => {
    if (item?.value !== undefined && item?.value !== invoice?.option) {
      setInvoice({
        option: item.value,
      });
    }
    if (item) handleOnChangeEnableGoNext?.(true);
  };

  useEffect(() => {
    const goNextButton = document.getElementById('sticky-offer-next');
    const trackGoNext = () => {
      trackLink(invoice?.option === InvoiceType?.PAPER ? 'formato cartaceo' : 'e-mail');
    };
    if (invoice?.option) {
      goNextButton?.addEventListener('click', trackGoNext);
      setSelectedItem({
        value: invoice.option,
        title: '',
      });
      handleOnChangeEnableGoNext?.(true);
    }
    return () => {
      goNextButton?.removeEventListener('click', trackGoNext);
    };
  }, [invoice]);

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.INVOICE);
    if (
      getCurrentUserType() === IUserType.NEXT_USER_INACTIVE &&
      owningIndividual?.contactMedium &&
      owningIndividual?.contactMedium[0]?.emailAddress
    )
      setBillingEmail(owningIndividual?.contactMedium[0]?.emailAddress);
    if (
      !invoice?.option &&
      (isRecognizedUser() || getCurrentUserType() === IUserType.LOGGED_USER)
    ) {
      handleSelect({
        value: 'eBill',
        title: '',
      });
    }
  }, []);

  return (
    <>
      <CheckboxCards
        items={items}
        title={title}
        description={description}
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
        onSelectItem={handleSelect}
        value={invoice.option}
      />
      <VfModal isOpen={isOpen} handleClose={() => setIsOpen(false)} isModalFullContainer>
        <EditEmailModal
          cmsData={email}
          onSubmit={handleSubmitNewEmail}
          isLoading={isBillDeliveryLoading || isRefetchLoading}
          isError={isBillDeliveryError || isRefetchError}
        />
      </VfModal>
    </>
  );
};

export default ChooseInvoice;
