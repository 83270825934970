import {
  COOKIE_TECH_APPOINTMENT,
  getProductCategory,
  IAddonCMS,
  IAddonDetailsCMS,
  IDevice,
  IIdentifyResponse,
  IProduct,
  ISku,
  LOGGED_USER_KEYS,
  retrieveCartCookie,
} from '@vfit/consumer/data-access';
import { QueryClient } from 'react-query';
import crypto from 'crypto-es';
import { checkWindow, getFromLocalStorageByKey, setItemCookie } from '@vfit/shared/data-access';
import {
  IOrganizedSoftProducts,
  PageProduct,
  TVisitorLoginStatus,
  VisitorTrackingOpts,
} from './interfaces';

const removeCurrency = (currencyString: string): string => currencyString.replace(/[^0-9,-]+/g, '');

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const clearTextFromHtml = (htmlText: string): string => htmlText?.replace(/<\/?[^>]+(>|$)/g, '');

/**
 * Method used to retrieve a number price in the format: nn.nn
 * @param text
 */
const retrievePriceFromText = (text: string): string => {
  let price = '';
  const regex = /(\d+(\.\d{3})*,?\d*)\s*€/;
  const match = text?.match(regex);
  if (match && match[1]) {
    const numericPrice = parseFloat(match[1].replace('.', '').replace(',', '.'));
    price = numericPrice.toFixed(2).toString();
  }
  return price;
};

/**
 * Method to return the products by organized products
 * @param queryClient
 * @param products
 */
const getOrganizedSoftProductsByProducts = (
  queryClient: QueryClient,
  products: IProduct[]
): IOrganizedSoftProducts => {
  const allProductIds = [];
  const allProductNames = [];
  const allProductPrices = [];
  const allProductCategory = [];
  let productQuantity = 0;
  if (products.length > 0) {
    productQuantity = products.length;
    products.forEach((prod) => {
      allProductIds.push(prod.offerId);
      allProductNames.push(prod.slug);
      allProductPrices.push(removeCurrency(prod.price));
      allProductCategory.push(getProductCategory(prod?.offerType));
    });
  }
  return {
    product_id: allProductIds,
    product_name: allProductNames,
    product_price: allProductPrices,
    product_category: allProductCategory,
    product_quantity: productQuantity.toString(),
  };
};

/**
 * Method to return the devices by organized devices
 * @param queryClient
 * @param devices
 */
const getOrganizedSoftDevicesByDevices = (
  queryClient: QueryClient,
  devices: IDevice[]
): IOrganizedSoftProducts => {
  const allDeviceIds = [];
  const allDeviceNames = [];
  const allDevicePrices = [];
  const allDeviceCategory = [];
  let deviceQuantity = 0;
  if (devices.length > 0) {
    deviceQuantity = devices.length;
    devices.forEach((device) => {
      const clearedText = retrievePriceFromText(device.devicedetail?.lowestPrice || '');
      allDeviceIds.push(device.devicedetail.slug);
      allDeviceNames.push(device.devicedetail.deviceName);
      allDevicePrices.push(removeCurrency(clearedText));
      allDeviceCategory.push(device.devicedetail.category);
    });
  }

  return {
    product_id: allDeviceIds,
    product_name: allDeviceNames,
    product_price: allDevicePrices,
    product_category: allDeviceCategory,
    product_quantity: deviceQuantity.toString(),
  };
};

/**
 * Method to return the user logged/not logged
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getUserInfo = (queryClient?): VisitorTrackingOpts => {
  const { SHA256 } = crypto;
  const user = getFromLocalStorageByKey('user');
  const silentLogin: any = getFromLocalStorageByKey('silentLogin');
  const identifyEnrichement: IIdentifyResponse = getFromLocalStorageByKey('identify');
  const isLoggedUser = LOGGED_USER_KEYS.includes(silentLogin?.flowId) || false;
  const customerData: any = getFromLocalStorageByKey('customerData');
  const appSessionFromStorage: any = checkWindow() && localStorage.getItem('appSession');
  const appSession: any = !!appSessionFromStorage && JSON.parse(appSessionFromStorage);
  const appMsisdn: string = appSession?.value;
  const visitorLoginStatus: TVisitorLoginStatus =
    appMsisdn || isLoggedUser ? 'logged in' : 'logged out';
  const visitorType: string = (localStorage.getItem('userType') || '').replace('anonymous', '');

  return {
    visitor_login_type: 'web',
    ...(!!retrieveCartCookie() && { visitor_type: `returning visitor ${visitorType}`.trim() }),
    ...(user?.customer?.[0].id && { visitor_customer_id: user?.customer[0].id }),
    ...(user?.taggedInfoCust?.email && {
      visitor_id_email: SHA256(user?.taggedInfoCust?.email).toString(),
    }),
    ...(user?.customer?.[0].msisdns && {
      visitor_id_asset_list: user?.customer?.[0].msisdns?.map(
        (msisdn) => SHA256(msisdn)?.toString() || ''
      ),
    }),
    ...(identifyEnrichement &&
      identifyEnrichement.id && {
        visitor_id_asset_active: SHA256(identifyEnrichement.id).toString(),
      }),
    ...(appMsisdn && { visitor_id_asset_active: SHA256(appMsisdn).toString() }),
    ...(visitorLoginStatus && { visitor_login_status: visitorLoginStatus }),
    ...(customerData &&
      customerData[0]?.owningIndividual?.birthDate && {
        visitor_customer_dob: customerData[0]?.owningIndividual?.birthDate,
      }),
    ...(customerData &&
      customerData[0]?.owningIndividual?.gender && {
        visitor_customer_gender: customerData[0]?.owningIndividual?.gender,
      }),
    ...(customerData &&
      customerData[0]?.billingAddress?.city && {
        visitor_customer_location_city: customerData[0]?.billingAddress?.city,
      }),
    ...(customerData &&
      customerData[0]?.owningIndividual?.nation && {
        visitor_customer_location_country: customerData[0]?.owningIndividual?.nation,
      }),
    ...(customerData &&
      customerData[0]?.billingAddress?.postalCode && {
        visitor_customer_location_id: customerData[0]?.billingAddress?.postalCode,
      }),
    ...(customerData &&
      customerData[0]?.owningIndividual?.firstName && {
        visitor_customer_name_first: customerData[0]?.owningIndividual?.firstName,
      }),
    ...(customerData &&
      customerData[0]?.owningIndividual?.lastName && {
        visitor_customer_name_last: customerData[0]?.owningIndividual?.lastName,
      }),
    ...(customerData &&
      customerData[0]?.billingAddress?.stateOrProvince && {
        visitor_customer_region: customerData[0]?.billingAddress?.stateOrProvince,
      }),
  };
};

const encryptData = (value: string): string => {
  const { SHA256 } = crypto;
  return SHA256(value).toString();
};

/**
 * Check if product is a promo prod
 * @Todo integrate logic from be
 * @param slug
 */
const getProductLabelPromo = (slug: string): string => {
  // TODO: Check product promo integration
  const promoProds = ['internet-unlimited-special-edition'];
  let labelToReturn = slug;
  if (slug && promoProds.includes(slug)) {
    labelToReturn += `:promo`;
  }
  return labelToReturn;
};

/**
 * Method to return the PageProduct
 * @param product
 */
const getPageProductsInfo = (product: IProduct): PageProduct => ({
  product_name: product?.slug || '',
  product_price: product?.price ? removeCurrency(product?.price) : '',
  product_id: `${product?.offerId}`,
  product_quantity: '1',
  product_category: [getProductCategory(product?.offerType)],
});

/**
 *
 * @param addons - list of addon added to cart, only CMS_ID. The value can be found in checkout context.
 * @param queryClient
 * @returns - A list of addons with all the data configured on cms for each one of them.
 */
const retrieveDetailsCartAddons = (
  addons: string[],
  queryClient: QueryClient
): IAddonDetailsCMS[] => {
  const addonsCMS = (queryClient.getQueryData('getCmsAddons') as IAddonCMS) || {};
  const addonIdList = Object.keys(addonsCMS).filter((addonCMSId) => addons.includes(addonCMSId));
  return addonIdList.map((id) => ({
    cmsId: id,
    ...addonsCMS[id],
  }));
};
/**
 *
 * @param product - The offer in the cart during the flow
 * @param addonList - Array of addons added to cart with all cms details (basically a filtered list of cms addons)
 */
const getCartProductsInfoWithAddon = (product?: IProduct, addonList?: IAddonDetailsCMS[]) => ({
  cart_products_id: [
    `${product?.offerId}` ?? '',
    ...(addonList?.map((addon) => addon?.addondetails?.id ?? '') ?? []),
  ],
  cart_products_name: [
    product?.slug ?? '',
    ...(addonList?.map((addon) => addon?.addondetails?.name ?? '') ?? []),
  ],
  cart_products_category: ['fixed', ...(addonList ? addonList.map(() => 'addon') : [])],
  cart_products_price: [
    `${product?.cartPrices?.offerPrice.amount}` ||
      (product.price ? removeCurrency(product.price) : ''),
    ...(addonList?.map((addon) => addon?.addondetails?.common?.priceRecurring ?? '') ?? []),
  ],
  cart_products_quantity: ['1', ...(addonList?.map(() => '1') ?? [])],
  cart_total: product?.price ? removeCurrency(product.price) : '',
});

/**
 * Retrieve info for device product
 * @param devicedetail
 * @param selectedModel
 */
const getProductDeviceInfo = ({ devicedetail }: IDevice, selectedModel?: ISku): PageProduct => ({
  product_price: retrievePriceFromText(selectedModel?.price || ''),
  product_quantity: devicedetail?.models?.length.toString() || '',
  product_name: devicedetail?.deviceName || '',
  product_category: devicedetail?.category || '',
  product_id: selectedModel?.sku || '',
});

const createTechAppointmentMockCookie = (urlRedirect?: string) => {
  const cookie = {
    timestamp: Date.now(),
    technology: 'MAKE',
    portability: 'N',
    buildingId: '',
    contactPhoneNumber: '3491303527',
    customerFiscalCode: 'XBSTTM92R05A045P',
    provinceOfInstallation: 'MI',
    techAppointmentReminder: 'Y',
    customer: {
      firstName: 'testMAKEzero',
      lastName: 'xbs',
      city: 'Milano',
      displayStateOrProvince: 'MI',
      note: 'Citofono numero 2',
      postalCode: '20146',
      street: 'Via Lorenteggio',
      streetNumber: '240',
    },
  };
  setItemCookie(COOKIE_TECH_APPOINTMENT, cookie, 0.021);
  if (urlRedirect) {
    window.location.href = urlRedirect;
  }
};

export {
  getOrganizedSoftProductsByProducts,
  getOrganizedSoftDevicesByDevices,
  getUserInfo,
  encryptData,
  getProductLabelPromo,
  getPageProductsInfo,
  retrieveDetailsCartAddons,
  getCartProductsInfoWithAddon,
  getProductDeviceInfo,
  createTechAppointmentMockCookie,
};
