import { useQueryClient } from 'react-query';
import {
  COEXISTENCE_COOKIE_KEY,
  IGlobalPromoCMS,
  IPageProps,
  IProduct,
  IWidgetType,
  isR02User,
  overridePromo,
  usePage,
  pushWidgets,
  useTranslate,
} from '@vfit/consumer/data-access';
import { IPageNavigation } from '@vfit/shared/models';
import { SoftManagerService } from '@vfit/consumer/providers';
import { Footer, Header } from '@vfit/shared/components';
import { useEffect, useState } from 'react';
import { getItemCookie, useFooter, useHeader } from '@vfit/shared/data-access';
import { useTrackingProvider } from '@vfit/shared/data-access';
import TopProduct from '../../TopProduct/topProduct';
import Widgets from '../../Widgets/widgets';
import { WidgetWrapper } from '../Layout/pageLayout.style';
import { withProtected } from '../Hoc/withProtected';
import BrightSky from '../../BrightSky/brightSky';
import TaggingProductDetail from './productDetail.tagging';
import { StickyBannerHeader } from '../../../index';
import { checkProductConfigurations } from './productDetail.utils';

const ProductDetail = ({ isApp }: IPageProps) => {
  const { page, pageNavigation, product: stateProduct } = usePage();
  const client = useQueryClient();
  const { globalpromo } = (client?.getQueryData('getGlobalPromo') || {}) as IGlobalPromoCMS;
  const queryCustomHeader = client?.getQueryData('getCustomPageNavigation') as IPageNavigation;
  const pageProduct = overridePromo(stateProduct, globalpromo);
  const { isReady: isReadyTagging } = useTrackingProvider();
  const { translate } = useTranslate();
  const [widgets, setWidgets] = useState(pageProduct.productDetails?.widgets?.sliders);
  const { elements, haveHeader, isHeaderSmall, haveFooter, isNewHeader } = page?.params || {};
  const { header, tagging } = useHeader(pageNavigation);
  const { header: customHeader } = useHeader(queryCustomHeader);
  const { footer } = useFooter(pageNavigation);
  const product = checkProductConfigurations(overridePromo(pageProduct as IProduct, globalpromo));
  const enableHeader = haveHeader && !product?.pdpNoHeader && header;
  const enableFooter = haveFooter && !product?.pdpNoFooter && footer;
  const isExtendedHeader = isNewHeader || product?.pdpEnableNewHeader;

  const haveStickyOffer = !!document?.getElementById('sticky-offer-product');
  const enablePaddingWidget = !enableFooter && haveStickyOffer;

  client.setQueryData('pageDatalayer', {
    event: ['product_view'],
    event_label: SoftManagerService(client).getPromoLabel(product?.slug || ''),
    opts: elements?.taggingOptions,
    pageProduct: SoftManagerService(client).getPageProduct(product),
    cartProduct: undefined,
    visitorTrackingOpts: SoftManagerService(client).getUser(),
  });

  useEffect(() => {
    if (pageProduct.isGlobalPromoActive && product.productDetails?.widgets?.sliders) {
      setWidgets(
        pushWidgets(
          IWidgetType.IMAGE_TITLE_DESCRIPTION,
          product.productDetails.widgets.sliders,
          globalpromo
        )
      );
    }
  }, []);

  return (
    <>
      {enableHeader && !isApp && (
        <Header
          onTrack={tagging}
          header={header}
          lite={isHeaderSmall}
          onTranslate={translate}
          isExtendedHeader={isExtendedHeader}
          topHeaderBanner={elements?.topheaderbanner}
          extendedHeaderLogo={
            product?.pdpEnableCustomHeader ? customHeader?.body?.headerLogo?.image : ''
          }
        />
      )}
      {isReadyTagging && (
        <TaggingProductDetail
          taggingOptions={elements?.taggingOptions}
          product={pageProduct as IProduct}
        />
      )}
      {pageProduct?.pdpStickyBanner && pageProduct?.pdpStickyBanner?.showBanner && (
        <StickyBannerHeader bannerCustomConfig={{ ...pageProduct.pdpStickyBanner }} />
      )}
      <div className="noPadding">
        <TopProduct
          product={product}
          disclaimer={product?.pdpDisclaimer || ''}
          divider={product?.pdpDivider}
          isR02={() => isR02User(getItemCookie(COEXISTENCE_COOKIE_KEY))}
        />
      </div>
      {widgets && (
        <WidgetWrapper padding={enablePaddingWidget ? '5' : '0'}>
          <Widgets widgets={widgets} tagging={tagging} />
        </WidgetWrapper>
      )}
      {enableFooter && !isApp && (
        <WidgetWrapper padding={enablePaddingWidget ? '90' : '0'}>
          <Footer onTrack={`${tagging}_FOOTER`} footer={footer} subChildren={<BrightSky />} />
        </WidgetWrapper>
      )}
    </>
  );
};

const ProductDetailProtected = withProtected()(ProductDetail);

export default ProductDetailProtected;
