import { ICMSApiError, ICMSError, ICommonData, IOptionalText } from '@vfit/shared/models';
import { useEffect, useState } from 'react';
import {
  CONSUMER_CMS_SHOPPING_CART,
  ErrorCodes,
  errorMock,
  IGenericErrorCMS,
  IPortabilityMobileCMS,
  productSpecificError,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import {
  API,
  openPopup,
  setTrackLink,
  getFromLocalStorageByKey,
  trackLink,
} from '@vfit/shared/data-access';
import { ShadowsGroup } from '@vfit/shared/components';
import { useTracking } from '@vfit/shared/data-access';
import { CheckboxCards } from '@vfit/consumer/components';
import { organizePortabilityCard } from './portabilityCard.utils';
import { useCheckout } from '../../../iBuyMobile.context';
import { Skeleton } from './portabilityCard.style';
import { checkErrorCustomer, getUserInfo, getTagging } from '../../checkout.utils';
import { ID_FLOWS } from '../../checkout.constants';
import { IEditorialErrorConfig } from '../../checkout.models';
import { useReserveFlow } from '../../hooks/UseReserveFlow/useReserveFlow';
import { useAddressMobileFlow } from '../../hooks/UseAddressMobileFlow/useAddressMobileFlow';

const PortabilityCard = ({
  handleOnChangeEnableGoNext,
  handleGoPrevSlide,
  handleOnClose,
}: ICommonData) => {
  const releaseResource = getFromLocalStorageByKey('releaseResource');
  const {
    portability,
    keepsNumber,
    currentStepKey,
    cartAsyncInfo,
    isModalButtonSticky,
    customerFlow,
    checkoutErrors,
    product,
    setIsStartCustomerFlow,
    setCurrentStepKey,
    setKeepsNumber,
    setPortability,
    setSlidesGoBack,
    setBillingAddress,
    setShippingAddress,
    setIsDisabledBackButton,
  } = useCheckout();
  const { urlGenericErrorCallMeNow } = productSpecificError(product);
  const [isEnabledFLowReserve, setIsEnabledFlowReserve] = useState(false);
  const taggingMobile = getTagging('portability');
  const isLoadingSim = cartAsyncInfo?.simTypeSelection?.isLoading || false;
  const isLoadingCustomer =
    customerFlow?.postCustomer?.isLoading ||
    customerFlow?.patchCustomer?.isLoading ||
    customerFlow?.customerInfo?.isLoading ||
    customerFlow?.associateCustomer?.isLoading;
  const isErrorCustomer =
    customerFlow?.postCustomer?.isError ||
    customerFlow?.patchCustomer?.isError ||
    customerFlow?.customerInfo?.isError ||
    customerFlow?.associateCustomer?.isError;
  const errorCustomer =
    customerFlow?.postCustomer?.error ||
    customerFlow?.patchCustomer?.error ||
    customerFlow?.associateCustomer?.error;
  const isSuccessAssociate = customerFlow?.associateCustomer?.isSuccess;

  const { trackWithNewDatalayer, trackView } = useTracking({
    event: ['portability'],
    disableInitialTrack: true,
  });
  setTrackLink(trackView, 'portability');

  const portabilityCardCms = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_PORTABILITY_MOBILE
  ) as IPortabilityMobileCMS;

  const genericError = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;

  const { errorcomponent: errorComponentOrder } =
    (useCmsConfig(
      CONSUMER_CMS_SHOPPING_CART,
      API.CMS_GET_RECURRENT_ORDER_ERROR_MOBILE
    ) as ICMSApiError) || {};
  const orderErrors = errorComponentOrder?.errors || [];

  const { errorcomponent: errorComponentCustomer } =
    (useCmsConfig(CONSUMER_CMS_SHOPPING_CART, API.CMS_GET_CUSTOMER_ERROR_MOBILE) as ICMSApiError) ||
    {};
  const customerErrors = errorComponentCustomer?.errors || [];

  const errors: ICMSError[] = [...orderErrors, ...customerErrors];

  const { title, description, items, errorOnPortability, bottomText } =
    organizePortabilityCard(portabilityCardCms);

  const {
    data: dataAddress,
    isSuccess: isSuccessAddress,
    isLoading: isLoadingAddress,
    isError: isErrorAddress,
  } = useAddressMobileFlow({
    onConfirmAddress: true,
  });

  useReserveFlow({ flowEnabler: isEnabledFLowReserve });

  const handleSelect = (item: IOptionalText | undefined) => {
    const portabilityWant = item?.value === 'yes';
    setPortability({
      ...portability,
      portabilityWant,
    });
    localStorage.setItem('portability', item?.value === 'yes' ? '1' : '0');
  };

  useEffect(() => {
    const goNextButton = document.getElementById('sticky-offer-next');
    const trackGoNext = () => {
      trackLink(keepsNumber?.value === 'yes' ? 'yes portability' : 'no portability');
    };
    goNextButton?.addEventListener('click', trackGoNext);
    return () => {
      goNextButton?.removeEventListener('click', trackGoNext);
    };
  }, [keepsNumber]);

  useEffect(() => {
    handleOnChangeEnableGoNext?.(
      !isLoadingCustomer &&
        !isLoadingAddress &&
        !cartAsyncInfo?.simTypeSelection?.isLoading &&
        portability?.portabilityWant !== undefined &&
        !!keepsNumber &&
        currentStepKey === ID_FLOWS.PORTABILITY
    );
  }, [isLoadingCustomer, portability, cartAsyncInfo]);

  useEffect(() => {
    if (isSuccessAddress && dataAddress) {
      setBillingAddress(dataAddress);
      setShippingAddress(dataAddress);
      setIsStartCustomerFlow(true);
    }
  }, [isSuccessAddress, dataAddress]);

  useEffect(() => {
    if (isErrorCustomer) {
      const errorDetail: IEditorialErrorConfig = checkErrorCustomer(
        errors,
        errorCustomer?.errorCode || '',
        {
          title: genericError?.genericerror?.title || '',
          description:
            genericError?.genericerror?.description ||
            errorOnPortability?.errorMessage?.genericError?.title ||
            '',
        }
      );
      const getAction = (isPopup?: boolean) => {
        customerFlow?.resetPatchAndPost?.(customerFlow);
        switch (errorCustomer?.errorCode) {
          case ErrorCodes.CREATE_CUSTOMER_FISCAL_CODE_ALREADY_PRESENT:
            setSlidesGoBack(4);
            break;
          case ErrorCodes.CREATE_CUSTOMER_DOCUMENT_ALREADY_PRESENT:
          case ErrorCodes.CREATE_CUSTOMER_MISSING_NATION_ADDRESS:
            if (errorDetail?.isPopup && isPopup) {
              openPopup(errorDetail?.url || urlGenericErrorCallMeNow, () => {
                if (handleOnClose) {
                  handleOnClose();
                }
              });
            } else {
              setSlidesGoBack(2);
            }
            break;
          case ErrorCodes.CREATE_CUSTOMER_PHONE_NUMBER_ALREADY_PRESENT:
          case ErrorCodes.CREATE_CUSTOMER_EMAIL_ADDRESS_ALREADY_PRESENT:
          case ErrorCodes.CREATE_CUSTOMER_CONTACT_ALREADY_PRESENT:
            setSlidesGoBack(3);
            break;
          case ErrorCodes.ASSOCIATE_CUSTOMER_BLOCKED:
            if (errorDetail?.isPopup && isPopup) {
              openPopup(errorDetail?.url || urlGenericErrorCallMeNow, () => {
                if (handleOnClose) {
                  handleOnClose();
                }
              });
            } else if (handleOnClose) {
              handleOnClose();
            }
            break;
          default:
            if (handleOnClose) {
              handleOnClose();
            }
            break;
        }
      };
      checkoutErrors?.showAndTrackError?.(
        {
          genericerror: {
            title: errorDetail?.title || '',
            description: errorDetail?.message || '',
            buttonLabel: errorDetail?.actionText || '',
          },
        },
        getAction,
        errorCustomer,
        '',
        '',
        () => getAction(true),
        ''
      );
    }
  }, [isErrorCustomer]);

  useEffect(() => {
    if (isErrorAddress) {
      const closeModalAction = () => {
        handleGoPrevSlide?.();
      };
      checkoutErrors?.showAndTrackError?.(
        genericError,
        closeModalAction,
        errorMock('address', { url: 'validateAddress', status: '500', statusText: '500' }),
        undefined,
        undefined,
        closeModalAction,
        errorOnPortability?.errorMessage?.genericError?.buttonLabel,
        () => openPopup(product?.genericErrorCallMeNow?.url || '', closeModalAction),
        product?.removeCTC ? undefined : product?.genericErrorCallMeNow?.label,
        isModalButtonSticky,
        true
      );
    }
  }, [isErrorAddress]);

  useEffect(() => {
    if (isEnabledFLowReserve) setIsEnabledFlowReserve(false);
  }, [isEnabledFLowReserve]);

  useEffect(() => {
    setIsDisabledBackButton(isLoadingCustomer || isLoadingAddress || isLoadingSim);
  }, [isLoadingCustomer, isLoadingAddress, isLoadingSim]);

  useEffect(() => {
    if (isSuccessAssociate) {
      setCurrentStepKey(ID_FLOWS.PORTABILITY);
      trackWithNewDatalayer({
        event: ['checkout_step4'],
        event_label: 'portability',
        opts: taggingMobile?.opts,
        cartProduct: taggingMobile?.trackingProduct,
        pageProduct: taggingMobile?.pageProductsInfo,
        visitorTrackingOpts: getUserInfo(),
      });
    }
  }, [isSuccessAssociate]);

  useEffect(() => {
    if (!releaseResource) {
      setIsEnabledFlowReserve(true);
    }
  }, []);

  if (!isSuccessAssociate) {
    return (
      <Skeleton>
        <ShadowsGroup quantity={4} heights={75} space={40} />
      </Skeleton>
    );
  }

  return (
    <CheckboxCards
      title={title}
      items={items}
      description={description}
      bottomText={bottomText}
      value={portability.portabilityWant || false}
      selectedItem={keepsNumber}
      setSelectedItem={setKeepsNumber}
      disableItems={false}
      onSelectItem={handleSelect}
    />
  );
};

export default PortabilityCard;
