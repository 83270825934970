import { useState, useEffect } from 'react';
import { API, setTrackLink } from '@vfit/shared/data-access';
import { ICommonData, IOptionalText } from '@vfit/shared/models';
import {
  CONSUMER_CMS_SHOPPING_CART,
  IPortabilityCMS,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { removeCurrency } from '@vfit/consumer/hooks';
import { useTracking } from '@vfit/shared/data-access';
import { CheckboxCards } from '@vfit/consumer/components';
import { ShadowsGroup } from '@vfit/shared/components';
import { useQueryClient } from 'react-query';
import { organizePortabilityLineCard } from './portabilityLineAppCard.utils';
import { useCheckout } from '../../../iBuyFixed.context';
import { getTagging, getUserInfo } from '../../checkout.utils';
import { ID_FLOWS } from '../../checkout.constants';
import { Skeleton } from './portabilityLineAppCard.style';
import { useIAddFirstCardFlow } from '../../hooks/UseIAddFirstCardFlow/useIAddFirstCardFlow.hook';
import { retrieveProduct } from '../../../iBuyFixed.utils';

const PortabilityLineAppCard = (props: ICommonData) => {
  const { handleOnChangeEnableGoNext, handleOnClose } = props;
  const product = retrieveProduct();
  const { portability, lockInAppFlow, setPortability, setCurrentStepKey } = useCheckout();
  const { isLoading } = useIAddFirstCardFlow(props);
  const queryClient = useQueryClient();
  const taggingFixed = getTagging('portability', 'fixed', queryClient);
  const [selectedItem, setSelectedItem] = useState<IOptionalText | undefined>(
    portability?.portabilityLine
      ? {
          title: '',
          value: portability?.portabilityLine || '',
        }
      : undefined
  );

  const { trackView } = useTracking({
    event: ['checkout_step4'],
    event_label: 'portability',
    opts: taggingFixed.opts,
    cartProduct: {
      ...taggingFixed.trackingProduct,
      cart_total: product?.price && removeCurrency(product.price),
    },
    pageProduct: taggingFixed?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'portability');

  const dataFromCms = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_PORTABILITY
  ) as IPortabilityCMS;

  const { cardData } = organizePortabilityLineCard(dataFromCms);

  const handleSelect = (item: IOptionalText | undefined) => {
    setPortability({
      ...portability,
      portabilityLine: item?.value || '',
      portabilityWant: false,
    });
    localStorage.setItem('portability', item?.value === 'yes' ? '1' : '0');
  };

  useEffect(() => {
    const goNextButton = document.getElementById('sticky-offer-next');
    const trackGoNext = () => {
      let event_label: string;
      if (selectedItem?.value === 'yes') {
        event_label = 'yes with phone';
      } else if (selectedItem?.value === 'internetOnly') {
        event_label = 'yes internet only';
      } else {
        event_label = 'no portability';
      }
      trackView({
        event_name: 'ui_interaction',
        event_label_track: event_label,
        event_category: 'users',
        event_action: 'click',
        tracking_type: 'link',
      });
    };
    handleOnChangeEnableGoNext?.(!!selectedItem);
    goNextButton?.addEventListener('click', trackGoNext);
    return () => {
      goNextButton?.removeEventListener('click', trackGoNext);
    };
  }, [selectedItem]);

  useEffect(() => {
    const backButton = document.getElementById('back-button');
    const backAndClose = () => {
      lockInAppFlow?.resetLockInAppFlow?.();
      handleOnClose?.();
    };
    backButton?.addEventListener('click', backAndClose);
    setCurrentStepKey(ID_FLOWS.PORTABILITY_LINE_APP);
    return () => {
      backButton?.removeEventListener('click', backAndClose);
    };
  }, []);

  if (isLoading) {
    return (
      <Skeleton>
        <ShadowsGroup quantity={4} heights={75} space={40} />
      </Skeleton>
    );
  }

  return (
    <CheckboxCards
      title={cardData.title}
      items={cardData.items}
      value={portability.portabilityLine}
      selectedItem={selectedItem}
      setSelectedItem={setSelectedItem}
      onSelectItem={handleSelect}
    />
  );
};

export default PortabilityLineAppCard;
