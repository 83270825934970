import {
  ALTERNATIVEOFFERIDKEY,
  CONSUMER_CMS_SHOPPING_CART,
  getFormattedPriceString,
  getHubPath,
  getProductCategory,
  handleFirstCharacterOfSentence,
  ICarouselCMS,
  ICommonLabelsInStepper,
  IGlobalPromoCMS,
  ITechs,
  IWidgetType,
  organizeCommonLabelsInStepper,
  overridePromo,
  pushWidgets,
  START_FROM_APP_LOCKIN,
  START_FROM_LOCKIN,
  START_PRODUCT_HUB,
  useCmsConfig,
  usePage,
} from '@vfit/consumer/data-access';
import { API } from '@vfit/shared/data-access';
import { Fade } from 'react-awesome-reveal';
import { ImageDivider } from '@vfit/shared/atoms';
import { ISupportModule, ProductDetail, StickyOffer } from '@vfit/shared/components';
import { removeCurrency } from '@vfit/consumer/hooks';
import { Widgets } from '@vfit/consumer/components';
import { useQueryClient } from 'react-query';
import TopShoppingCart from '../TopShoppingCart/topShoppingCart';
import { getLocalAddress, getTechLabels } from '../../shoppingCart.utils';
import { useCheckout } from '../../../iBuyFixed.context';
import { WidgetWrapper } from './shoppingCartContent.style';
import { isEnabledWaitingCart } from '../../../iBuyFixed.utils';
import { IShoppingCartContent } from './shoppingCartContent.models';

const ShoppingCartContent = ({ isSuccessDynamicCart }: IShoppingCartContent) => {
  const queryClient = useQueryClient();
  const { product: pageProduct } = usePage();
  const {
    product: retrievedProduct,
    isShowCheckout,
    isLoadingCart,
    setIsShowCheckout,
  } = useCheckout();
  const { globalpromo } = (queryClient?.getQueryData('getGlobalPromo') || {}) as IGlobalPromoCMS;
  const product = overridePromo(retrievedProduct, globalpromo);
  const IS_LOADING_CART = isEnabledWaitingCart() ? isLoadingCart : !isSuccessDynamicCart;
  const startingHubTech = localStorage.getItem(START_PRODUCT_HUB);
  const hub = pageProduct ? getHubPath(pageProduct) || '' : getHubPath(retrievedProduct) || '';
  const allTechs = useCmsConfig(CONSUMER_CMS_SHOPPING_CART, API.CMS_GET_TECNOLOGIES) as ITechs;
  const supportModulesCms = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_SUPPORT_MODULE
  ) as ISupportModule;
  const techLabels = getTechLabels(allTechs);
  const commonLabels = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_STEPPER_COMMON_LABELS_FIXED
  ) as ICommonLabelsInStepper;
  const { openProductDetails, closeProductDetails } = organizeCommonLabelsInStepper(commonLabels);
  const haveStickyOffer = !!document?.getElementById('sticky-offer-summary');
  const isAppLockInRetrieved = window.localStorage.getItem(START_FROM_APP_LOCKIN);
  const isAlternativeOffer = localStorage.getItem(ALTERNATIVEOFFERIDKEY);
  const isAppLockIn: boolean = !!isAppLockInRetrieved && JSON.parse(isAppLockInRetrieved as string);

  const getWidgets = (): ICarouselCMS[] => {
    const {
      offerLockInWeb: lockInWeb,
      offerLockInApp: lockInApp,
      checkout,
      isLockInMMProduct,
      isLockInProduct,
    } = product;
    const startFromLockin = localStorage.getItem(START_FROM_LOCKIN);
    const { lockInHub, lockInMM } = lockInWeb || {};
    if (!isAppLockIn && !isLockInMMProduct && !isLockInProduct) {
      return checkout?.widgetscheckout?.sliders || [];
    }
    if (isAppLockIn && lockInApp) {
      return lockInApp?.widgets?.sliders || [];
    }
    if (startFromLockin && ((isLockInProduct && lockInHub) || lockInHub)) {
      return lockInHub?.widgets?.sliders && lockInHub?.widgets?.sliders.length > 0
        ? lockInHub?.widgets?.sliders || []
        : checkout?.widgetscheckout?.sliders || [];
    }
    if ((isLockInMMProduct && lockInMM) || (isAlternativeOffer && isLockInProduct && lockInMM)) {
      return lockInMM?.widgets?.sliders || [];
    }
    return checkout?.widgetscheckout?.sliders || [];
  };

  const getOrganizedWidgets = (): ICarouselCMS[] => {
    const sliders = getWidgets();
    return product.isGlobalPromoActive
      ? pushWidgets(IWidgetType.IMAGE_TITLE_DESCRIPTION, sliders, globalpromo)
      : sliders;
  };

  return (
    <>
      <TopShoppingCart
        hub={startingHubTech || hub}
        titleOfferMap={techLabels?.bestOfferChoiceMap}
        editAddressLabel={techLabels?.editAddress}
        isSuccessDynamicCart={isSuccessDynamicCart}
      />
      {product?.shoppingCart?.shoppingCartDivider?.image &&
        product?.shoppingCart?.shoppingCartDivider?.text && (
          <Fade direction="up" triggerOnce>
            <ImageDivider
              image={product?.shoppingCart?.shoppingCartDivider?.image}
              alt={product?.shoppingCart?.shoppingCartDivider.text || ''}
            />
          </Fade>
        )}
      {!isShowCheckout && (
        <StickyOffer
          id="sticky-offer-summary"
          title={getFormattedPriceString(product.price, product.recurrence)}
          isLoading={IS_LOADING_CART}
          description={{ openProductDetails, closeProductDetails }}
          ctaActivation={{
            action: () => setIsShowCheckout(true),
            label: product?.shoppingCart?.buttonFooter?.buttonGoNext || 'Prosegui',
          }}
          showDetailLabel={openProductDetails}
          hideDetailLabel={closeProductDetails}
          topLabel={product?.topLabelStickyCheckout || ''}
          content={
            <ProductDetail
              trackingOpt={{
                event: ['view'],
                event_label: 'offer details',
                opts: {
                  product_target_segment: 'consumer',
                  page_section: 'fixed offers',
                  page_subsection: 'checkout',
                },
                cartProduct: {
                  cart_product_id: `${product?.offerId}`,
                  cart_product_name: product?.slug,
                  cart_product_category: getProductCategory(product?.offerType || ''),
                  cart_product_price: product?.price && removeCurrency(product.price),
                  cart_product_quantity: '1',
                },
              }}
              details={{
                title: product.title,
                conditions: product.conditions,
                callMeNowHelp: product.callMeNowHelp,
                description: product.description,
                offerDetails: product.offerDetails,
                price: product.price,
                recurrence: product.recurrence,
                originalPrice: product.originalPrice,
              }}
              modalInfo={{
                title: supportModulesCms?.supportmodules?.needHelp?.title || '',
                ctaName: supportModulesCms?.supportmodules?.needHelp?.ctaSupportCall || '',
              }}
              address={
                getLocalAddress()?.displayedAddress
                  ? `<h5>${
                      product?.shoppingCart?.addressLabel || ''
                    }<br/><b>${handleFirstCharacterOfSentence(
                      getLocalAddress()?.displayedAddress || ''
                    )}</b></h5>`
                  : ''
              }
            />
          }
        />
      )}
      {!IS_LOADING_CART && (
        <WidgetWrapper padding={haveStickyOffer ? '5' : '0'}>
          <Widgets widgets={getOrganizedWidgets()} />
        </WidgetWrapper>
      )}
    </>
  );
};

export default ShoppingCartContent;
