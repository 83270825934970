import { useEffect } from 'react';
import {
  CONSUMER_CMS_SHOPPING_CART,
  useCmsConfig,
  IPortabilityMobileCMS,
  productSpecificError,
  useGetPortabilityInfoMobile,
  ErrorCodes,
  IGenericErrorCMS,
  errorMock,
  IDigitalOperatorsConfig,
  ICommonLabelsInStepper,
  organizeCommonLabelsInStepper,
} from '@vfit/consumer/data-access';
import { API, defineYupSchema, openPopup, regex, setTrackLink } from '@vfit/shared/data-access';
import { useTracking } from '@vfit/shared/data-access';
import { ShadowsGroup } from '@vfit/shared/components';
import { ICMSApiError, ICMSError } from '@vfit/shared/models';
import {
  organizePortabilityError,
  organizePortabilityNumberCard,
} from './portabilityNumberPortabilityRequiredCard.utils';
import { ICheckoutData, IEditorialErrorConfig } from '../../checkout.models';
import { useCheckout } from '../../../iBuyMobile.context';
import { ID_FLOWS } from '../../checkout.constants';
import { checkErrorCustomer, getUserInfo, getTagging } from '../../checkout.utils';
import { Skeleton } from './portabilityNumberPortabilityRequiredCard.style';
import { DEFAULT_PORTABILITY } from '../../../iBuyMobile.context.data';
import { useReserveFlow } from '../../hooks/UseReserveFlow/useReserveFlow';
import PortabilityNumber from './components/PortabilityNumber/portabilityNumber';
import { useAddressMobileFlow } from '../../hooks/UseAddressMobileFlow/useAddressMobileFlow';

const PortabilityNumberPortabilityRequiredCard = ({
  handleOnChangeEnableGoNext,
  handleGoPrevSlide,
  handleOnClose,
}: ICheckoutData) => {
  const {
    portability,
    cartAsyncInfo,
    isModalButtonSticky,
    customerFlow,
    checkoutErrors,
    product,
    setPortability,
    setCurrentStepKey,
    setIsValidNumberPortability,
    setBillingAddress,
    setShippingAddress,
    setSlidesGoBack,
    setIsStartCustomerFlow,
  } = useCheckout();
  const isWinBackProduct = product?.isWinback;
  const isRequiredPortability = product?.isRequiredPortability;
  const { buttonGenericError, urlGenericErrorCallMeNow } = productSpecificError(product);
  const genericError = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;

  const { errorcomponent: errorComponentOrder } =
    (useCmsConfig(
      CONSUMER_CMS_SHOPPING_CART,
      API.CMS_GET_RECURRENT_ORDER_ERROR_MOBILE
    ) as ICMSApiError) || {};
  const { blacklist } =
    (useCmsConfig(
      CONSUMER_CMS_SHOPPING_CART,
      API.CMS_GET_DIGITAL_BUNDLE_OPERATORS
    ) as IDigitalOperatorsConfig) || {};
  const orderErrors = errorComponentOrder?.errors || [];

  const { errorcomponent: errorComponentCustomer } =
    (useCmsConfig(CONSUMER_CMS_SHOPPING_CART, API.CMS_GET_CUSTOMER_ERROR_MOBILE) as ICMSApiError) ||
    {};
  const commonLabels = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_STEPPER_COMMON_LABELS_MOBILE
  ) as ICommonLabelsInStepper;
  const { goBackInStepper } = organizeCommonLabelsInStepper(commonLabels);

  const customerErrors = errorComponentCustomer?.errors || [];

  const errors: ICMSError[] = [...orderErrors, ...customerErrors];
  const isLoadingSim = cartAsyncInfo?.simTypeSelection?.isLoading;
  const isLoadingCustomer =
    customerFlow?.postCustomer?.isLoading ||
    customerFlow?.patchCustomer?.isLoading ||
    customerFlow?.customerInfo?.isLoading ||
    customerFlow?.associateCustomer?.isLoading;
  const isSuccessCustomer =
    customerFlow?.postCustomer?.isSuccess || customerFlow?.patchCustomer?.isSuccess;
  const isErrorCustomer =
    customerFlow?.postCustomer?.isError ||
    customerFlow?.patchCustomer?.isError ||
    customerFlow?.associateCustomer?.isError;
  const errorCustomer =
    customerFlow?.postCustomer?.error ||
    customerFlow?.patchCustomer?.error ||
    customerFlow?.associateCustomer?.error;
  const cmsApiKey = isWinBackProduct ? API.CMS_GET_WINBACK_DATA : API.CMS_GET_PORTABILITY_MOBILE;
  const portabilityNumberCardCMS = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    cmsApiKey
  ) as IPortabilityMobileCMS;
  const { title, description, label, disclaimer, validationMessage } =
    organizePortabilityNumberCard(portabilityNumberCardCMS);
  const { titleError, messageError, buttonLabelError } =
    organizePortabilityError(portabilityNumberCardCMS);

  const {
    isLoading: isLoadingPortInfo,
    isError: isErrorPortInfo,
    error: errorPortIn,
  } = useGetPortabilityInfoMobile(
    portabilityNumberCardCMS.portabilitymobile.operatorList,
    portabilityNumberCardCMS.portabilitymobile.operatorsOrder,
    blacklist?.elements
  );

  const taggingMobile = getTagging('portability');

  const { trackView } = useTracking({
    event: ['checkout_step4.1'],
    event_label: 'number portability',
    opts: taggingMobile?.opts,
    cartProduct: taggingMobile?.trackingProduct,
    pageProduct: taggingMobile?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'number portability');

  const {
    data: dataAddress,
    isSuccess: isSuccessAddress,
    isLoading: isLoadingAddress,
    isError: isErrorAddress,
    errorMessage: errorMessageAddress,
  } = useAddressMobileFlow({
    onConfirmAddress: true,
  });

  useReserveFlow({ flowEnabler: isSuccessCustomer });

  const checkPortabilityValidation = () => {
    if (portability?.portabilityNumber) {
      const isMatches = portability.portabilityNumber.match(regex['mobilePhoneNumber']);
      handleOnChangeEnableGoNext?.(!!isMatches);
    }
  };

  useEffect(() => {
    if (isErrorCustomer) {
      const errorDetail: IEditorialErrorConfig = checkErrorCustomer(
        errors,
        errorCustomer?.errorCode || '',
        {
          title: genericError?.genericerror?.title || 'Ops',
          description: genericError?.genericerror?.description || '',
        }
      );
      trackView({
        event_name: 'page_error',
        page_error: `${errorCustomer?.errorType}| ${errorCustomer?.errorMessage} | ${errorDetail.message}`,
        event_category: 'error',
        page_error_code: errorCustomer?.errorType,
        page_type: 'error page',
      });
      const getAction = () => {
        customerFlow?.resetPatchAndPost?.(customerFlow);
        switch (errorCustomer?.errorCode) {
          case ErrorCodes.CREATE_CUSTOMER_FISCAL_CODE_ALREADY_PRESENT:
          case ErrorCodes.CREATE_CUSTOMER_DOCUMENT_ALREADY_PRESENT:
            setSlidesGoBack(2);
            break;
          case ErrorCodes.CREATE_CUSTOMER_MISSING_NATION_ADDRESS:
            setSlidesGoBack(3);
            break;
          case ErrorCodes.CREATE_CUSTOMER_PHONE_NUMBER_ALREADY_PRESENT:
          case ErrorCodes.CREATE_CUSTOMER_EMAIL_ADDRESS_ALREADY_PRESENT:
          case ErrorCodes.CREATE_CUSTOMER_CONTACT_ALREADY_PRESENT:
            setSlidesGoBack(2);
            break;
          case ErrorCodes.ASSOCIATE_CUSTOMER_BLOCKED:
            if (errorDetail?.isPopup) {
              openPopup(errorDetail?.url || urlGenericErrorCallMeNow, () => {
                if (handleOnClose) {
                  handleOnClose();
                }
              });
            } else if (handleOnClose) {
              handleOnClose();
            }
            break;
          default:
            if (handleOnClose) {
              handleOnClose();
            }
            break;
        }
      };
      checkoutErrors?.showAndTrackError?.(
        {
          genericerror: {
            title: errorDetail?.title || '',
            description: errorDetail?.message || '',
            buttonLabel: errorDetail?.actionText || 'Ti chiamiamo noi',
          },
        },
        getAction,
        errorCustomer,
        '',
        '',
        () => getAction(),
        ''
      );
    }
  }, [isErrorCustomer]);

  useEffect(() => {
    if (isSuccessAddress && dataAddress) {
      setBillingAddress(dataAddress);
      setShippingAddress(dataAddress);
      setIsStartCustomerFlow(true);
    }
  }, [isSuccessAddress, dataAddress]);

  useEffect(() => {
    if (isErrorAddress) {
      const closeModalAction = () => {
        handleGoPrevSlide?.();
      };
      checkoutErrors?.showAndTrackError?.(
        genericError,
        closeModalAction,
        errorMock('address', { url: 'validateAddress', status: '500', statusText: '500' }),
        undefined,
        errorMessageAddress,
        closeModalAction,
        goBackInStepper || 'Torna indietro',
        () => openPopup(product?.genericErrorCallMeNow?.url || '', closeModalAction),
        product?.removeCTC ? undefined : product?.genericErrorCallMeNow?.label,
        isModalButtonSticky,
        true
      );
    }
  }, [isErrorAddress]);

  useEffect(() => {
    if (isErrorPortInfo) {
      const action = () => {
        if (handleGoPrevSlide) handleGoPrevSlide();
      };
      checkoutErrors?.showAndTrackError?.(
        genericError,
        action,
        errorMock('portininfo', errorPortIn),
        titleError,
        messageError,
        action,
        goBackInStepper || 'Torna indietro',
        () => openPopup(urlGenericErrorCallMeNow, action),
        product?.removeCTC ? undefined : buttonGenericError,
        isModalButtonSticky,
        true
      );
    }
  }, [isErrorPortInfo]);

  useEffect(() => {
    checkPortabilityValidation();
  }, [portability]);

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.PORTABILITY_NUMBER);
    checkPortabilityValidation();
    if (isRequiredPortability) localStorage.setItem('portability', '1');
  }, []);

  const onChangeData = (telephoneNumber: string) => {
    setIsValidNumberPortability(false);
    setPortability({
      ...DEFAULT_PORTABILITY,
      portabilityWant: isRequiredPortability ? true : portability.portabilityWant,
      portabilityNumber: telephoneNumber || '',
    });
  };

  if (isLoadingPortInfo || isLoadingCustomer || isLoadingAddress || isLoadingSim) {
    return (
      <Skeleton>
        <ShadowsGroup heights={70} quantity={3} />
      </Skeleton>
    );
  }

  return (
    <PortabilityNumber
      validationMessage={validationMessage}
      title={title}
      description={description}
      disclaimer={disclaimer}
      label={label}
      value={portability.portabilityNumber}
      onChangeData={onChangeData}
      disabled={isWinBackProduct}
      schema={defineYupSchema('phoneNumber')}
    />
  );
};

export default PortabilityNumberPortabilityRequiredCard;
