import { useEffect, useState } from 'react';
import {
  CONSUMER_CMS_SHOPPING_CART,
  IPortabilityMobileCMS,
  IProduct,
  useCmsConfig,
  useGenerateOTP,
  useVerifyOTP,
} from '@vfit/consumer/data-access';
import { API, setTrackLink } from '@vfit/shared/data-access';
import { resetData, useTracking } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { ICommonData } from '@vfit/shared/models';
import { organizeOtpPortability, Otp } from '@vfit/consumer/components';
import { useCheckout } from '../../../iBuyMobile.context';
import { OTP_CONSTANTS } from './portabilityOtpCard.utils';
import { getUserInfo, getTagging } from '../../checkout.utils';

const PortabilityOtpCard = ({ handleOnChangeEnableGoNext }: ICommonData) => {
  const queryClient = useQueryClient();
  const generateOtp = useGenerateOTP(OTP_CONSTANTS.MODULE_NAME);
  const { portability, product, setPortability } = useCheckout();
  const [isError, setIsError] = useState(false);
  const {
    mutate,
    data: verifyOtpData,
    isSuccess,
    isError: verifyOptError,
    isLoading,
  } = useVerifyOTP();
  const taggingMobile = getTagging('OTP port');

  const {
    portabilitymobile: { otp: dataFromCms },
  } = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_PORTABILITY_MOBILE
  ) as IPortabilityMobileCMS;

  const handleSendOtp = (field: string, fieldType: string) => {
    resetData(queryClient, ['generateOTP']);
    generateOtp.mutate({
      identifier: `39${field}`,
      identifierType: fieldType,
    });
  };

  const { trackView } = useTracking({
    event: ['checkout_step4.5'],
    event_label: 'OTP',
    opts: taggingMobile?.opts,
    cartProduct: taggingMobile?.trackingProduct,
    pageProduct: taggingMobile?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'port');

  const handleVerify = (otp: string) => {
    mutate(otp);
  };

  const handleSetError = () => {
    setIsError(false);
  };

  useEffect(() => {
    handleSendOtp(portability.portabilityNumber, OTP_CONSTANTS.FIELD_TYPE);
    return () => {
      resetData(queryClient, ['generateOTP', 'verifyOTP']);
    };
  }, []);

  useEffect(() => {
    if (verifyOptError || (!verifyOptError && isSuccess && !verifyOtpData)) {
      setIsError(true);
    } else if (verifyOtpData?.id) {
      setPortability({
        ...portability,
        portabilityOtp: { isVerified: true, numberValidate: portability.portabilityNumber },
      });
      setTimeout(() => {
        handleOnChangeEnableGoNext?.(true);
      }, 500);
    }
  }, [isSuccess, verifyOtpData, verifyOptError]);

  return (
    <Otp
      product={product as IProduct}
      contactMedium={{
        field: portability.portabilityNumber,
        fieldType: OTP_CONSTANTS.FIELD_TYPE,
      }}
      isError={isError}
      isLoading={isLoading}
      reSendOtp={handleSendOtp}
      onVerify={handleVerify}
      setIsError={handleSetError}
      noAccessEnabled={false}
      configOtp={organizeOtpPortability(dataFromCms, true, {
        field: portability.portabilityNumber,
        fieldType: OTP_CONSTANTS.FIELD_TYPE,
      })}
    />
  );
};

export default PortabilityOtpCard;

