import {
  CONSUMER_CMS_SHOPPING_CART,
  IContactMedium,
  useCmsConfig,
  usePatchCustomer,
  IGenericErrorCMS,
  ICoverageToolCongifg,
  DEFAULT_CONTACT_ADDRESS,
  recognizedUser,
  IPersonalInfoCmsMobile,
  organizeEditAddressManual,
  errorMock,
  ErrorCodes,
} from '@vfit/consumer/data-access';
import { useEffect, useState } from 'react';
import { AddNumber, EditField, DeliveryAddress } from '@vfit/consumer/components';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoggerInstance } from '@vfit/shared/data-access';
import { API, openPopup, trackLink, trackView } from '@vfit/shared/data-access';
import {
  IAddressObject,
  ICMSApiError,
  ICoverageToolType,
  IEditAddressCmsConfig,
  IInputsCoverageTool,
  IOptionalText,
} from '@vfit/shared/models';
import { LoadingSpinner, LinkWithIcon } from '@vfit/shared/atoms';
import { AddressManual, Form, VfModal } from '@vfit/shared/components';
import { contactSchema } from './contact.validation';
import {
  organizeContactMobile,
  organizeContact,
  handleCRMCodes,
  handleErrorCustomer,
} from './contact.utils';
import {
  IContactData,
  IContactMobile,
  IContactType,
  IModalConfig,
  IOperationType,
} from './contact.models';
import {
  Checkbox,
  CheckboxDiv,
  Div,
  Field,
  Footer,
  FooterDiv,
  ReadOnlyDiv,
  Text,
  Title,
  Wrapper,
} from './contact.style';
import { useCheckout } from '../../../../../iBuyMobile.context';
import { updateContactDataLocal } from '../../contactCard.utils';
import { EMAIL_CUSTOMER } from '../../../../checkout.models';

const DEFAULT_ADDRESS: IInputsCoverageTool = {
  placeId: '',
  city: '',
  postalCode: '',
  stateOrProvince: '',
  streetNumber: '',
  street: '',
  completeAddress: '',
  typeCoverage: ICoverageToolType.AUTO,
};

const Contact = ({
  data,
  address,
  onChangeData,
  checkInfo,
  onChangeCheck,
  showEditMail = true,
  setIsValidForm,
}: IContactData) => {
  const { owningIndividual } = data;
  const ContactCMS = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_PERSONAL_DATA_MOBILE
  ) as IPersonalInfoCmsMobile;

  const coverageToolConfig = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_COVERAGE_TOOL_CONFIG
  ) as ICoverageToolCongifg;

  const editAddressManual = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_EDIT_ADDRESS_MOBILE
  ) as IEditAddressCmsConfig;

  const editAddressManualConfig = organizeEditAddressManual(editAddressManual);

  const { product, owningData, checkoutErrors, findCaller, isModalButtonSticky, setOwningData } =
    useCheckout();
  const [completeAddressValue, setCompleteAddressValue] = useState(address?.completeAddress || '');
  const [isHiddenStreetNumber, setIsHiddenStreetNumber] = useState(address?.isHiddenStreetNumber);

  // console.log('allineamento hidden', address?.street, address?.streetNumber);

  const [isOpenManualAddress, setIsOpenManualAddress] = useState<boolean>(false);
  const {
    isError: patchCustomerError,
    error: errorPatchCustomer,
    mutateAsync,
  } = usePatchCustomer();

  const [modalConfig, setModalConfig] = useState<IModalConfig>({
    isOpen: false,
    type: IContactType.EMAIL,
    operationType: IOperationType.EDIT,
  });

  const [indexs, setIndexs] = useState<{ email: number; phone: number }>({
    email: -2,
    phone: -2,
  });

  // VALIDATE THE FORM
  const checkIsValid = () => {
    const { owningIndividual: owningIndividualLocal } = owningData;
    const phone = owningIndividualLocal?.contactMedium?.find(
      (contact) => contact.type === IContactType.MSISDN
    );
    const email = owningIndividualLocal?.contactMedium?.find(
      (contact) => contact.type === IContactType.EMAIL
    );
    return !!(
      phone?.phoneNumber &&
      email?.emailAddress &&
      phone.phoneNumber.substring(0, 1) === '3'
    );
  };

  const checkIsValidWithAddress = () => {
    const { owningIndividual: owningIndividualLocal } = owningData;
    const phone = owningIndividualLocal?.contactMedium?.find(
      (contact) => contact.type === IContactType.MSISDN
    );
    const email = owningIndividualLocal?.contactMedium?.find(
      (contact) => contact.type === IContactType.EMAIL
    );

    const placeId = owningData?.address?.placeId;
    const streetNumber = owningData?.address?.streetNumber;
    return !!(
      phone?.phoneNumber &&
      email?.emailAddress &&
      phone.phoneNumber.substring(0, 1) === '3' &&
      placeId &&
      streetNumber &&
      streetNumber !== ''
    );
  };

  const [loading, setLoading] = useState(false);

  const genericError = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;

  const { errorcomponent: customerErrors } =
    (useCmsConfig(CONSUMER_CMS_SHOPPING_CART, API.CMS_GET_CUSTOMER_ERROR_MOBILE) as ICMSApiError) ||
    {};

  const methods = useForm<IContactMobile>({
    resolver: yupResolver(contactSchema(coverageToolConfig)),
    defaultValues: {
      ...(Object.keys(address).length === 0 ? DEFAULT_ADDRESS : address),
    },
    mode: 'all',
  });

  const {
    register,
    getValues,
    setValue,
    formState: { isValid, errors },
    trigger,
  } = methods;

  const handleSubmit = (
    picked: IAddressObject,
    pikedForMutation: {
      region: string | undefined;
      latitude: string;
      longitude: string;
      placeId: string;
    }
  ) => {
    const formattedCompleteAddress = `${picked?.street}, ${picked?.streetNumber}, ${picked?.city}, ${picked?.stateOrProvince}`;

    onChangeData?.(
      {
        ...data,
        address: {
          ...picked,
          placeId: pikedForMutation.placeId,
          completeAddress: formattedCompleteAddress,
          isHiddenStreetNumber: true,
        },
        isValid,
      },
      isValid
    );
    setCompleteAddressValue(formattedCompleteAddress);
    setIsValidForm?.(checkIsValidWithAddress());
    // This solve the problem but is not good

    // we are using  VALENTI FORM and after closing the modal is not necessary to call onchange or other method inside VALENTI FORM
    // only set the value and the useEffect connect to the state of VALENTI FORM will do the work for us
    setValue('city', picked.city);
    setValue('placeId', pikedForMutation.placeId);
    setValue('postalCode', picked.postalCode);
    setValue('stateOrProvince', picked.stateOrProvince);
    setValue('street', picked.street);
    setValue('streetNumber', picked.streetNumber);
    setValue('completeAddress', formattedCompleteAddress);
    trigger();
    // const formValenti = getValues();
    // console.log({ formValenti });
    setIsOpenManualAddress(false);
  };

  const handleOpenModal = () => {
    setIsOpenManualAddress(true);
    trackView({
      event_name: 'view',
      event_label_track: 'manual address input',
      event_category: 'tools',
      page_type: 'address',
    });
  };

  const createField = (type: IContactType, value: string, preferred: boolean) => {
    if (!owningIndividual.contactMedium) return;
    owningIndividual?.contactMedium.push(
      type === IContactType.EMAIL
        ? {
            type,
            preferred,
            emailAddress: value,
          }
        : {
            type,
            preferred,
            phoneNumber: value,
          }
    );
    const contactMediumLength: number = (owningIndividual?.contactMedium?.length ?? 0) - 1;
    if (type === IContactType.EMAIL) indexs.email = contactMediumLength;
    else indexs.phone = contactMediumLength;
    setIndexs(indexs);
    setOwningData({
      ...owningData,
      owningIndividual,
    });
  };

  const findIndex = (type: IContactType) => {
    let index = -2;
    if (owningIndividual?.contactMedium) {
      index = owningIndividual?.contactMedium.findIndex((e) => e.type === type && e.preferred);
      if (index === -1) {
        index = owningIndividual?.contactMedium.findIndex((e) => e.type === type);
        if (index === -1) {
          createField(type, '', true);
        }
      }
    }
    return index;
  };

  const setShowValues = () => {
    const shouldValidate = (item: string): boolean => item !== '';
    const email = findIndex(IContactType.EMAIL);
    const phone = findIndex(IContactType.MSISDN);
    const emailAddress = owningIndividual?.contactMedium?.[email]?.emailAddress || '';
    const phoneNumber = owningIndividual?.contactMedium?.[phone]?.phoneNumber || '';

    if (email !== -1) {
      indexs.email = email;
      setValue('emailAddress', emailAddress, {
        shouldValidate: shouldValidate(emailAddress),
      });
    }
    if (phone !== -1) {
      indexs.phone = phone;
      setValue('phoneNumber', phoneNumber, {
        shouldValidate: shouldValidate(phoneNumber),
      });
    }
    setIndexs(indexs);
  };

  useEffect(() => {
    if (owningData.isLogged) {
      setTimeout(() => {
        if (onChangeData)
          onChangeData(
            {
              ...data,
              owningIndividual,
            },
            checkIsValid()
          );
      }, 250);
    }
    if (
      owningData.isValid &&
      owningData?.address?.placeId !== undefined &&
      owningData?.address?.streetNumber !== ''
    ) {
      setTimeout(() => {
        if (onChangeData)
          onChangeData(
            {
              ...data,
              owningIndividual,
            },
            checkIsValidWithAddress()
          );
      }, 250);
    }
  }, []);

  useEffect(() => {
    setShowValues();
    const inputs = getValues();
    const addressInputs = {
      ...inputs,
      completeAddress: completeAddressValue,
      isHiddenStreetNumber,
    };
    delete addressInputs.emailAddress;
    delete addressInputs.phoneNumber;
    if (onChangeData)
      onChangeData(
        {
          ...data,
          owningIndividual,
          address: addressInputs,
        },
        isValid
      );
  }, [isValid, completeAddressValue]);

  useEffect(() => {
    setIsValidForm?.(isValid);
  }, [isValid]);

  useEffect(() => {
    if (patchCustomerError && modalConfig.operationType !== IOperationType.ERROR) {
      const backendError = errorPatchCustomer.headers?.get('ERR-backendErrorCode') || '';
      const errorCode = handleCRMCodes(backendError);
      const errorDetails = handleErrorCustomer(
        errorCode,
        customerErrors?.errors || [],
        genericError
      );
      checkoutErrors?.showAndTrackError?.(
        genericError,
        () => {},
        patchCustomerError && errorPatchCustomer
          ? errorMock(ErrorCodes.PATCH_CUSTOMER, errorPatchCustomer, ErrorCodes.PATCH_CUSTOMER)
          : undefined,
        errorDetails?.title,
        errorDetails?.message,
        () => {},
        errorDetails?.actionText,
        () => openPopup(product?.genericErrorCallMeNow?.url || ''),
        product?.removeCTC ? '' : product?.genericErrorCallMeNow?.label || '',
        isModalButtonSticky,
        true
      );
    }
  }, [patchCustomerError]);

  const { footerInfo, subtitle, title, telephoneLable, emailLable } = organizeContact(ContactCMS);
  const CMS_LABELS = organizeContactMobile(ContactCMS);

  const onCheckClickHandler = () => {
    onChangeCheck({ checkContactProblem: !checkInfo?.checkContactProblem });
  };

  const resetModal = () => {
    setModalConfig({
      isOpen: false,
      operationType: IOperationType.EDIT,
      type: IContactType.EMAIL,
    });
  };

  const onChangeNumber = (value: IOptionalText) => {
    setLoading(true);
    mutateAsync([
      {
        updatedValue: value.value as string,
        type: 'number',
        operation: 'replace',
        index: 1,
      },
    ])
      .then(() => {
        if (owningIndividual?.contactMedium) {
          owningIndividual?.contactMedium.forEach((p, index) => {
            if (p.type === IContactType.MSISDN && owningIndividual?.contactMedium?.[index]) {
              owningIndividual.contactMedium[index].phoneNumber = value.value;
            }
          });
        }
        const newOwningIndividual = {
          ...data,
          owningIndividual,
        };
        setOwningData({
          ...newOwningIndividual,
        });
        updateContactDataLocal({
          ...newOwningIndividual,
        });
        setLoading(false);
        resetModal();
      })
      .catch((e) => {
        setLoading(false);
        LoggerInstance.debug(e);
      });
  };

  const modifyField = (value: { phoneNumber?: string; email?: string; isEdit?: boolean }) => {
    if (value.phoneNumber) {
      onChangeNumber({ title: '', value: value.phoneNumber });
    } else {
      setLoading(true);
      mutateAsync([
        {
          updatedValue: value.email as string,
          type: 'email',
          operation: 'replace',
          index: 0,
        },
      ])
        .then(() => {
          if (owningIndividual?.contactMedium) {
            owningIndividual.contactMedium[indexs.email].emailAddress = value.email;
          }
          const newOwningIndividual = {
            ...data,
            owningIndividual,
          };
          setOwningData({
            ...newOwningIndividual,
          });
          localStorage.setItem(EMAIL_CUSTOMER, value?.email || '');
          updateContactDataLocal({
            ...newOwningIndividual,
          });
          setLoading(false);
          resetModal();
        })
        .catch((e) => {
          setLoading(false);
          LoggerInstance.debug(e);
        });
    }
  };

  const getAllNumbers = (): IContactMedium[] | undefined =>
    findCaller.subscription
      ?.filter((sub) => sub.serviceType === 'M')
      .map((sub) => ({
        type: 'phone',
        preferred: false,
        phoneNumber: sub.msisdn,
      }));

  const isAddModal = (): boolean =>
    !(!getAllNumbers() || (getAllNumbers() && getAllNumbers()?.length === 0));

  const openModal = (typeContact: IContactType, operationType: IOperationType) => {
    setModalConfig({
      isOpen: true,
      type: typeContact,
      operationType,
    });
  };

  // region Render Already exists / not exists customer
  const alreadyExistsCustomer = () => (
    <ReadOnlyDiv>
      <Field>
        {CMS_LABELS.roTelephoneLable}
        <p>
          {owningIndividual?.contactMedium && indexs.phone !== -2
            ? owningIndividual?.contactMedium[indexs.phone]?.phoneNumber
            : ''}
        </p>
        <LinkWithIcon
          onClick={() => {
            trackLink(CMS_LABELS?.roCtaTelephone);
            openModal(IContactType.MSISDN, isAddModal() ? IOperationType.ADD : IOperationType.EDIT);
          }}
          text={CMS_LABELS.roCtaTelephone}
        />
      </Field>
      <Field>
        {CMS_LABELS.roEmailLable}
        <p>
          {owningIndividual?.contactMedium && indexs.email !== -2
            ? owningIndividual?.contactMedium[indexs.email]?.emailAddress
            : ''}
        </p>
        {showEditMail && (
          <LinkWithIcon
            onClick={() => {
              trackLink(CMS_LABELS?.roCtaTelephone);
              openModal(IContactType.EMAIL, IOperationType.EDIT);
            }}
            text={CMS_LABELS.roCtaEmail}
          />
        )}
      </Field>
    </ReadOnlyDiv>
  );

  const changeData = () => {
    const inputs = getValues();
    const addressInputs = {
      ...inputs,
      completeAddress: completeAddressValue,
      isHiddenStreetNumber,
    };
    if (owningIndividual?.contactMedium) {
      owningIndividual.contactMedium[indexs.email].emailAddress = inputs.emailAddress;
      owningIndividual.contactMedium[indexs.phone].phoneNumber = inputs.phoneNumber;
    }

    delete addressInputs.emailAddress;
    delete addressInputs.phoneNumber;

    if (onChangeData)
      onChangeData(
        {
          ...data,
          owningIndividual,
          address: addressInputs,
        },
        isValid
      );
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === ' ') {
      e.preventDefault();
    }
  };

  const renderDeliveryMobile = () => {
    const deliveryAddress = (
      <DeliveryAddress
        handleOpenModal={handleOpenModal}
        labels={ContactCMS?.personalinfomobile?.contactAddress || DEFAULT_CONTACT_ADDRESS}
        state={Object.keys(address).length === 0 ? DEFAULT_ADDRESS : address}
        onChangeData={() => changeData()}
        onChangeCompleteAddress={(completeAddress) => {
          setCompleteAddressValue(completeAddress);
        }}
        onChangeHiddenStreetNumber={(isHidden) => {
          setIsHiddenStreetNumber(isHidden);
        }}
      />
    );

    if (isOpenManualAddress) {
      return null;
    }

    return deliveryAddress;
  };

  const notExistsCustomer = () => (
    <FormProvider {...methods}>
      <Div>
        <div className="textInput">
          <Form.TextInput
            label={telephoneLable}
            error={errors.phoneNumber?.message}
            autoComplete="off"
            placeholder=" "
            {...register('phoneNumber', {
              onChange: changeData,
            })}
          />
        </div>
        <div className="textInput">
          <Form.TextInput
            label={emailLable}
            type="email"
            error={errors.emailAddress?.message}
            autoComplete="off"
            placeholder=" "
            onKeyDown={(e) => handleKeyDown(e as unknown as KeyboardEvent)}
            {...register('emailAddress', {
              onChange: (e) => {
                setValue('emailAddress', e.target.value.replace(/\s+/g, ''));
                changeData?.();
              },
            })}
          />
        </div>
      </Div>
      {ContactCMS.personalinfomobile?.contactAddress && renderDeliveryMobile()}
      <VfModal isOpen={isOpenManualAddress} handleClose={() => setIsOpenManualAddress(false)}>
        <AddressManual
          cmsConfig={editAddressManualConfig}
          onSubmit={handleSubmit}
          state={address}
        />
      </VfModal>
    </FormProvider>
  );

  return (
    <Wrapper>
      <Title>{title}</Title>
      <Text>{recognizedUser() ? CMS_LABELS.roSubtitle : subtitle}</Text>

      {recognizedUser() ? alreadyExistsCustomer() : notExistsCustomer()}
      <Footer>
        <CheckboxDiv>
          <Checkbox>
            <input
              type="checkbox"
              checked={checkInfo.checkContactProblem}
              onChange={onCheckClickHandler}
            />
            <span className="checkmark" />
          </Checkbox>
        </CheckboxDiv>
        <FooterDiv>{footerInfo}</FooterDiv>
      </Footer>
      <VfModal
        isOpen={modalConfig.isOpen && modalConfig.operationType === IOperationType.ADD}
        handleClose={resetModal}
      >
        <>
          {loading && <LoadingSpinner />}
          <AddNumber
            onChangeNumber={onChangeNumber}
            isLoading={loading}
            data={getAllNumbers()}
            selectedNumber={owningIndividual?.contactMedium?.[1].phoneNumber || ''}
          />
        </>
      </VfModal>
      <VfModal
        isOpen={modalConfig.isOpen && modalConfig.operationType === IOperationType.EDIT}
        handleClose={resetModal}
      >
        <>
          {loading && <LoadingSpinner />}
          <EditField
            isLoading={loading}
            onSubmit={modifyField}
            handleClose={resetModal}
            isPhone={modalConfig.type === IContactType.MSISDN}
          />
        </>
      </VfModal>
    </Wrapper>
  );
};

export default Contact;
