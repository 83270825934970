import { useEffect } from 'react';
import { API, setTrackLink, setTrackView } from '@vfit/shared/data-access';
import {
  useCmsConfig,
  CONSUMER_CMS_SHOPPING_CART,
  IDeliveryInformationMobile,
  organizeEditAddressMobile,
  useGetPaymean,
  IGlobalConfigCMS,
} from '@vfit/consumer/data-access';
import { useTracking } from '@vfit/shared/data-access';
import {
  ECodeIdentification,
  IDeliveryOptions,
  IEditAddressCmsConfig,
  IIdentificationDetails,
} from '@vfit/shared/models';
import { useCheckout } from '../../../iBuyMobile.context';
import { getUserInfo, getTagging } from '../../checkout.utils';
import { ID_FLOWS } from '../../checkout.constants';
import YourAddress from './components/YourAddress/yourAddress';
import { getDataOrganized } from './yourAddressCard.utils';
import { ICheckoutData } from '../../checkout.models';

const YourAddressCard = ({ handleOnChangeEnableGoNext }: ICheckoutData) => {
  const {
    owningData: { isLogged, owningIndividual },
    billingAddress,
    shippingAddress,
    simOptions,
    termsAndConditions,
    deliveryAdvance,
    recognitionCode,
    customerDeliveryBaseInfo,
    setCurrentStepKey,
    setTermsAndConditions,
    setIsStartAddress,
  } = useCheckout();
  const taggingMobile = getTagging('address confirmation');
  const globalConfig = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GLOBAL_CONFIG
  ) as IGlobalConfigCMS;
  const deliveryInformation = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_DELIVERY_INFORMATION_MOBILE
  ) as IDeliveryInformationMobile;
  const editAddressDataMobile = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_EDIT_ADDRESS_MOBILE
  ) as IEditAddressCmsConfig;
  useGetPaymean({ enabled: isLogged }, globalConfig);
  const editAddressConfigCms = organizeEditAddressMobile(editAddressDataMobile?.editaddressconfig);
  const identificationDetails: IIdentificationDetails | IIdentificationDetails[] =
    deliveryAdvance?.identificationDetails;
  const deliveryOptions: IDeliveryOptions[] =
    customerDeliveryBaseInfo?.deliveryBase?.deliveryOptions || [];
  const selectedSimOption = simOptions?.simOptions?.find((simOption) => simOption.selection);
  const haveDI = deliveryOptions?.find((el) => el.code === ECodeIdentification.DI && el.isSelected);
  const isEsim = selectedSimOption?.value?.toLowerCase() === 'esim' || false;
  const isEsimFD = !!(isEsim && haveDI);
  const isCourier = recognitionCode === 'CO';

  const addressCmsData = getDataOrganized(
    deliveryInformation,
    owningIndividual,
    billingAddress,
    shippingAddress,
    editAddressConfigCms,
    isEsim,
    isEsimFD,
    isCourier
  );

  const { trackView } = useTracking({
    event: ['checkout_step5'],
    event_label: 'address confirmation',
    opts: taggingMobile?.opts,
    cartProduct: taggingMobile?.trackingProduct,
    pageProduct: taggingMobile?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'address confirmation');
  setTrackView(trackView);

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.DELIVERY_ADDRESS);
    setTermsAndConditions({
      ...termsAndConditions,
      preConsensus: true,
      optionToReconsider: 'no',
    });
    setIsStartAddress(true);
    if (isEsim) {
      localStorage.setItem('isEsim', '1');
    } else {
      localStorage.removeItem('isEsim');
    }
    handleOnChangeEnableGoNext?.(true);
  }, []);

  return <YourAddress {...addressCmsData} isEsimFD={isEsimFD} isEsim={isEsim} />;
};

export default YourAddressCard;
