import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, ShadowsGroup } from '@vfit/shared/components';
import { fonts } from '@vfit/shared/themes';
import { CustomText } from '@vfit/shared/atoms';
import { IPortabilityNumber, IPortabilityProps } from './portabilityNumber.models';
import { Wrapper, Text, Div, Title, Skeleton } from './portabilityNumber.style';
import { portabilityNumberSchema } from './portabilityNumber.validation';

const PortabilityNumber = ({
  title,
  description,
  label,
  value,
  validation,
  onChangeData,
  onValidChange,
  isLoading,
  schema,
  disabled,
  disclaimer,
}: IPortabilityProps) => {
  //
  const {
    register,
    setValue,
    getValues,
    formState: { isValid, errors },
  } = useForm<IPortabilityNumber>({
    resolver: yupResolver(schema || portabilityNumberSchema(validation)),
    mode: 'all',
  });

  useEffect(() => {
    if (value) {
      setValue('phoneNumber', value, { shouldValidate: !!value });
    }
  }, []);

  const changeData = () => {
    const inputs = getValues();
    const changedNumber = inputs.phoneNumber;
    if (onChangeData) {
      onChangeData(changedNumber);
    }
  };

  useEffect(() => {
    onValidChange?.(isValid);
  }, [isValid]);

  return (
    <Wrapper>
      {isLoading ? (
        <Skeleton>
          <ShadowsGroup heights={70} quantity={3} />
        </Skeleton>
      ) : (
        <>
          <Title>{title}</Title>
          <Text>{description}</Text>
          <Div>
            <div className="textInput">
              <Form.TextInput
                label={label}
                placeholder=" "
                autoComplete="off"
                disabled={disabled || false}
                error={errors.phoneNumber?.message}
                {...register('phoneNumber', {
                  onChange: changeData,
                  setValueAs: (v) => v.trim(),
                })}
              />
            </div>
          </Div>
          {disclaimer && (
            <CustomText
              text={disclaimer}
              size={20}
              sizeMobile={14}
              lineHeight={18}
              margin={[32, 0]}
              fontFamily={fonts.regular}
              textAlign="left"
            />
          )}
        </>
      )}
    </Wrapper>
  );
};

export default PortabilityNumber;
